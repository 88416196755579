import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from "formik";

import { MESSAGES, TOOLTIP } from "../../constants/messages";
import { DNID_NUMBER_REGEX } from "../../constants/dnid";
import { CSS_CLASSES } from "../../constants/css-classes";

import { APP_CONFIG } from "../../config/config";
import { envConfig } from "../../environment";

import PmivrOverlayTrigger from "../../components/common/overlay-trigger/pmivr-overlay-trigger";
import PmivrLabel from "../../components/common/label/pmivr-label";
import PmivrSelectOption from "../../components/common/select-option/pmivr-select";

import ClientService from "../../services/client.service";
import ConfigService from "../../services/config.service";

/**
 * Form for adding new dnid numbers
 * @param {Object} props Properties from parent component
 * @returns {React.Component} Html element to render
 */
const NumberModal = (props) => {

    // ui state object having following flags
    // disabled: flag to enable / disable the button
    // message: object having message text and error specifying flag
    const [uiState, setUiState] = useState({
        disabled: false, message: { text: '', isError: false },
        renderExt: (envConfig.REACT_APP_RENDER_EXTENSION_OPTION === true)
    });
    const [allowExtensions, setAllowExtensions] = useState(false);
    const [deploymentEnvState, setDeploymentEnvState] = useState({ environment: [], isEnvironmentExists: false });
    // State for tracking the selected zone option
    const [selectedZone, setSelectedZone] = useState('');
    // Array of zone options to be displayed in the dropdown
    const ZoneOptions = [
        { value: "US", label: "US" }, // Represents the US zone
        { value: "CAN", label: "CAN" }, // Represents the Canadian zone
    ];

    useEffect(() => {
        loadEnvironments();
    }, []);

    // loading the configured deployment environments and preparing the select options
    const loadEnvironments = async () => {
        const configuredEnv = await ConfigService.getDeploymentEnvironments();
        if (configuredEnv?.length) {
            let options = [];
            configuredEnv.map((env) => {
                if (env?.key) {
                    options.push({ text: env?.name, value: env?.key });
                }
            });
            setDeploymentEnvState({ ...deploymentEnvState, environment: options, isEnvironmentExists: true });
        }
    }

    /**
     * Handles the change event for the zone dropdown.
     * @param {Object} event - The change event triggered by the dropdown.
     */
    const handleZone = (event) => {
        // Updates the local state to reflect the selected zone value.
        setSelectedZone(event.target.value);
        // Updates the 'zone' field in the Formik form with the selected zone value.
        formik.setFieldValue('zone', event.target.value);
    };

    // validating the formik fields
    const validate = Yup.object({
        // input type for phone number is number. So, it will not accept any other character except numbers and +
        // length() method is not available directly on number schema type of Yup. It is available with string.
        phoneNumber: Yup.string()
            .length(10, MESSAGES.ERR.INVALID_DNID).required(MESSAGES.ERR.PHONE_NUMBER_REQUIRED)
            .matches(DNID_NUMBER_REGEX, {
                message: MESSAGES.ERR.INVALID_DNID,
                excludeEmptyString: false,
            }),
        environment: deploymentEnvState.isEnvironmentExists
            ? Yup.string().required(MESSAGES.ERR.SELECT_ENVIRONMENT) : Yup.string(),
        rangeStart: Yup.number(),
        rangeEnd: Yup.number().min(Yup.ref('rangeStart'), MESSAGES.ERR.INCORRECT_DNID_RANGE),
        zone: Yup.string()
    });

    // formik values for form
    const formik = useFormik({
        initialValues: { phoneNumber: 0, rangeStart: 0, rangeEnd: 0, environment: '', zone: '' },
        validationSchema: validate,
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });

    /**
     * Adding new dnid in DB
     * @param {{ phoneNumber, rangeStart, rangeEnd }} dnid info about dnid
     */
    const handleSubmit = async (dnid) => {
        setUIStateProps(true);
        try {
            // saving new dnid
            const response = await ClientService.saveDnidNumber(dnid);
            setUIStateProps(true, { text: response?.msg, isError: false })
            // giving timeout to remain on the same screen for displaying message
            setTimeout(() => { props?.closeAction(response?.data); }, APP_CONFIG.MESSAGE_TIMEOUT);
        } catch (err) {
            let _message = MESSAGES.SOMETHING_WENT_WRONG;
            if (err.response?.status === 403) {
                _message = MESSAGES.ERR.UNAUTHORIZED;
            } else if (err.response?.status === 409) {
                _message = MESSAGES.ERR.EXISTING_DNID;
            }
            setUIStateProps(false, { text: _message, isError: true });
        }
    }

    /**
     * Updating the ui state props in the state
     * @param {Boolean} disabled flag to disable the element like button
     * @param {{ text , isError }} message text message and flag specifying error message
     */
    const setUIStateProps = (disabled = false, message = { text: '', isError: false }) => {
        setUiState((prevState) => {
            const newState = { ...prevState };
            newState.disabled = disabled;
            newState.message.text = message.text;
            newState.message.isError = message.isError;
            return newState;
        });
    }

    /**
     * updating flag in state to enable/disable the extensions
     * if not enabled, then resetting the values of range start and range end in form
     */
    const enableExtensions = () => {
        if (!allowExtensions) {
            formik.values.rangeStart = 0;
            formik.values.rangeEnd = 0;
        }
        setAllowExtensions(!allowExtensions);
    }

    return (
        <>
            <div className="pmivr-card card-secondary pmivr-number-input">
                <div className={uiState.message.isError ? "field-error text-center" : "field-success text-center"}>{uiState.message?.text}</div>
                <form onSubmit={formik.handleSubmit}>
                    <div className="pmivr-container">
                        <div className="wrapper p-3 pt-0">
                            <div className="mb-2 remove-arrows">
                                {/* Phone Number */}
                                <PmivrLabel label="Phone Number" tooltip={TOOLTIP.INFO.PHONE_NUMBER} cssClass="mt-2" />
                                <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.PHONE_NUMBER}>
                                    <input type="number" name="phoneNumber" id="phoneNumber" value={formik.values.phoneNumber || ""}
                                        placeholder="Enter Phone Number" className="form-control pmivr-input" title="" autoFocus
                                        onChange={(event) => {
                                            // remove the error message when user again enters the number after seeing the error message
                                            setUIStateProps(false, { text: '', isError: false });
                                            formik.handleChange(event);
                                        }} onBlur={formik.handleBlur} />
                                </PmivrOverlayTrigger>
                                {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                                    <span className='field-error text-center'>{formik.errors.phoneNumber}</span>
                                )}
                                <PmivrSelectOption
                                    selectValue={selectedZone}
                                    onChange={handleZone}
                                    cssClass="mt-1"
                                    label="Select Zone"
                                    firstOption="Select Zone"
                                    options={ZoneOptions}
                                    tooltip="Select the zone corresponding to the phone number,  or leave it unchanged if not needed."
                                    selectedValue={selectedZone}
                                />
                                {/* Deployment Environmnt */}
                                {deploymentEnvState?.isEnvironmentExists && (
                                    <>
                                        <PmivrLabel label="Deployment Environment" tooltip={TOOLTIP.INFO.DEPLOYMENT_ENV} cssClass="mt-3" />
                                        <select className="pmivr-select" name="environment" value={formik.values.environment}
                                            onChange={formik.handleChange}>
                                            <option value='' disabled>Select</option>
                                            {
                                                [...deploymentEnvState?.environment?.values()].map((env, _index) => {
                                                    return (<option key={env.value} value={env.value}>
                                                        {env.text}
                                                    </option>)
                                                })
                                            }
                                        </select>
                                        {formik.touched.environment && formik.errors.environment && (
                                            <span className='field-error text-center'>{formik.errors.environment}</span>
                                        )}
                                    </>
                                )}
                                {/* Extension Option */}
                                <div className={uiState.renderExt ? CSS_CLASSES.BLOCK_DISPLAY : CSS_CLASSES.HIDE_DISPLAY}>
                                    <div className="form-check pmivr-check-radio">
                                        <input type="checkbox" className="form-check-input mt-3 checkbox"
                                            onChange={enableExtensions} checked={allowExtensions}
                                            value={allowExtensions} />
                                        <div className="pmivr-label">
                                            <label className="mt-3">Allow Extensions</label>
                                        </div>
                                    </div>
                                    <div className={allowExtensions ? CSS_CLASSES.BLOCK_DISPLAY : CSS_CLASSES.HIDE_DISPLAY}>
                                        <PmivrLabel label="Range Start" tooltip={TOOLTIP.INFO.NUMBER_RANGE_START} cssClass="mt-3" />
                                        <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.NUMBER_RANGE_START}>
                                            <input type="number" name="rangeStart" id="rangeStart" value={formik.values.rangeStart || ""}
                                                className="form-control pmivr-input" required={allowExtensions} onBlur={formik.handleBlur}
                                                placeholder="Enter Range Start" onChange={formik.handleChange} />
                                        </PmivrOverlayTrigger>

                                        <PmivrLabel label="Range End" tooltip={TOOLTIP.INFO.NUMBER_RANGE_END} cssClass="mt-3" />
                                        <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.NUMBER_RANGE_END}>
                                            <input type="number" name="rangeEnd" id="rangeEnd" value={formik.values.rangeEnd || ""}
                                                className="form-control pmivr-input" required={allowExtensions} onBlur={formik.handleBlur}
                                                placeholder="Enter Range End" onChange={formik.handleChange} />
                                        </PmivrOverlayTrigger>
                                        {formik.touched.rangeEnd && formik.errors.rangeEnd && (
                                            <span className='field-error text-center'>{formik.errors.rangeEnd}</span>
                                        )}
                                    </div>
                                </div>

                            </div>
                            <button className={`pmivr-btn-app w-100 p-3 mt-2`}
                                disabled={uiState.disabled} type="submit">Create</button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

NumberModal.propTypes = {
    // closing the user dialogue
    closeAction: PropTypes.func
}

export default NumberModal;