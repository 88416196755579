import { envConfig } from "../environment";

import appAxios from "../util/app.axios";

/**
 * Responsible for ivr call logs details and session details
 */
class VoiceFileManagerService {
    static BASE_URL = `${envConfig.REACT_APP_API_BASE_URL}`;
    static CLIENT_DATA_URL = `${envConfig.REACT_APP_API_BASE_URL}/clientData`;

    /**
     * Getting the payment types from configuration for the businessCode
     * @param {string} businessCode 
     * @param {string} appCode 
     * @param {string} environment 
     * @returns {Promise<Array<Object>>} Payment types for the given businessCode
     */
    static getMessageConfigByAppCodes(businessCode, appCode, environment) {
        const body = { "appCode": appCode, "environment": environment }
        const payloads = JSON.stringify(body);
        const filterString = encodeURIComponent(payloads);
        const url = `${this.CLIENT_DATA_URL}/config/message?businessCode=${businessCode}&filterString=${filterString}`;
        return appAxios.get(url);
    }

    /**
     * Updating or saving message configs into the database
     * @param {Object} data 
     * @param {string} businessCode 
     * @param {string} environment 
     * @returns {Promise<string>} if the data is saved or not
     */
    static updateMessageConfigs(data, businessCode, environment) {
        const body = { "environment": environment }
        const payloads = JSON.stringify(body);
        const filterString = encodeURIComponent(payloads);
        const url = `${this.BASE_URL}/config/message?businessCode=${businessCode}&filterString=${filterString}`;
        return appAxios.put(url, {
            actionMessages: data
        });
    }
}

export default VoiceFileManagerService;