import React, { useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { MESSAGES, TOOLTIP } from "../../../constants/messages";
import { CSS_CLASSES } from "../../../constants/css-classes";
import { VOICE_FILE_UPLOAD_TYPE } from "../../../constants/voice-file";

import PmivrOverlayTrigger from "../../../components/common/overlay-trigger/pmivr-overlay-trigger";
import PmivrSnackBar from "../../../components/common/dialog/pmivr-snackbar";
import AudioPlayer from "../../../components/common/audio-player/audio-player";

import AudioUtil from "../../../util/audio.util";
import AudioService from "../../../services/audio.service";

/**
 * Component for handling the uploading of voice files by the user.
 * This component allows the user to upload a voice file, track its upload state (file, file path, file size),
 * and provides feedback through a snackbar notification.
 *
 * @param {object} props - The properties passed to the component.
 * @param {string} props.filePath - The file path of the selected voice file.
 * @param {string} props.setFilePath - Sets the file path in parent component so that it updates the table with latest state.
 * @returns {JSX.Element} The rendered component, which contains the voice file upload functionality.
 */
const UploadVoiceFile = (props) => {
    const { filePath, setFilePath, selectedLanguage } = props;
    // Using the open method from the snackbar component to show upload status
    const snackbarRef = useRef();
    const location = useLocation();
    // getting the search params from url
    const searchQueryParams = new URLSearchParams(location.search);
    // extracting businessCode and environment from query params of the url
    const businessCode = searchQueryParams.get('businessCode');

    /**
     * State to manage file uploaded by user
     * file: file which is uploaded
     * filePath: filePath which is updated after being uploaded to server
     * fileSize: size of the file that is uploaded
     */
    const [uploadedFileInfo, setUploadedFileInfo] = useState({ file: null, filePath: "", fileSize: "" });

    /**
     * uploadBtnDisable {boolean} whether to disable or enable the upload button based on fields
     * infoMessage {string} message to show when the file is uploaded (success/failure)
     * isErrorMessage {boolean} whether the message is error message (in case of true) or success (in case of false)
     */
    const [uiState, setUiState] = useState({ uploadBtnDisable: true, infoMessage: "", isErrorMessage: false });

    // file should be MONO
    const CHANNELS = { MONO: 1 };

    /**
     * Update the state when voice file is uploaded on builder by user by generating wave audio data from buffer
     * @param {Object} event event of type object
     */
    const voiceFileUploadChangeHandler = async (event) => {
        try {
            // Check if the file extension is .wav
            if (event?.target?.files?.length && event?.target?.files[0].name.endsWith(".wav")) {
                const file = event.target.files[0];
                // read the file that is uploaded
                const reader = new FileReader();
                // runs when file reading is complete
                reader.onload = async (event) => {
                    // Decode the WAV audio data from the buffer of the file
                    const audioData = await AudioUtil.toAudioBuffer(event.target.result);
                    // Check if wav file is valid or not
                    if (!isWavFileValid(audioData)) {
                        setUiState({ ...uiState, infoMessage: `Unsupported File: ${file.name}`, isErrorMessage: true, uploadBtnDisable: true });
                        setUploadedFileInfo({ ...uploadedFileInfo, file: null });
                    } else {
                        setUploadedFileInfo({ ...uploadedFileInfo, file: file, filePath: file.name, fileSize: Math.floor((file.size) / 1000) });
                        setUiState({ ...uiState, infoMessage: `File Added Successfully`, isErrorMessage: false, uploadBtnDisable: false });
                    }
                };
                // Read the file as an array buffer
                reader.readAsArrayBuffer(file);
            } else {
                snackbarRef.current.open(MESSAGES.VOICE_FILE_UPLOAD_CORRECT_FORMAT);
            }
        } catch (err) {
            if (snackbarRef?.current) {
                snackbarRef.current.open(MESSAGES.ERR.VOICE_FILE_UPLOAD);
            }
        }
    };

    /**
     * Check if the wav file is valid or not
     * @param {Object} audioData data of the audio file
     * @returns {boolean} valid file or not
    */
    const isWavFileValid = (audioData) => {
        if (audioData.sampleRate !== 8000 || audioData.numberOfChannels !== CHANNELS.MONO) {
            return false;
        }
        return true;
    }

    /**
     * Uploads the audio file to repository and update the voice file info state of parent component
    */
    const uploadFile = async () => {
        try {
            const fileInfo = { businessCode: businessCode, language: selectedLanguage || "en", fileName: uploadedFileInfo.filePath?.replace(/\.wav$/, ''), folderName: "voice-file-manager" }
            fileInfo.file = uploadedFileInfo.file;
            const response = await AudioService.uploadVoiceFileToRepo(fileInfo);
            if (response?.data?.filePath) {
                const newFilePath = response.data.filePath.replace(/^voice-files\//, '').replace(/\.wav$/, '');
                const newInfo = {
                    filePath: newFilePath,
                    voiceFileType: VOICE_FILE_UPLOAD_TYPE.UPLOAD,
                    fileSize: uploadedFileInfo.fileSize
                }
                setUploadedFileInfo({ ...uploadedFileInfo, filePath: newFilePath });
                setFilePath(newFilePath);
                snackbarRef.current.open(MESSAGES.FILE_UPLOAD_SUCCESS);
                setUiState({ ...uiState, uploadBtnDisable: true });
            }
        } catch (err) {
            setUiState({ ...uiState, uploadBtnDisable: false });
            if (snackbarRef?.current) {
                snackbarRef.current.open(MESSAGES.ERR.FILE_UPLOAD);
            }
        }
    }

    /**
     * Updates the voice file info object with new details.
     * Old info will have details of the voice file along with respective file paths and voice file type of each language.
     * New info will have details of the voice file with updated filePath for the selected language.
     * Now need to update the entire voiceFileInfo which has info for each system voice file with the new information of selected voice file.
     * @param {Object} oldInfo - old details of the voice file with respective file paths for each language
     * @param {string} language - language of the voice file to be updated
     * @param {Object} newInfo - new details of the voice file with updated file path and voice file type
     * @returns {Object} full updated voiceFileInfo 
     */
    function _updateVoiceFileInfo(oldInfo, language, newInfo) {
        // Return a new object with the updated language info
        return {
            ...oldInfo,
            language: {
                ...oldInfo.language,
                [language]: {
                    ...oldInfo.language[language],
                    ...newInfo
                }
            }
        };
    }


    return (
        <div>
            <PmivrSnackBar ref={snackbarRef} />
            <div className="d-flex justify-content-between">
                <div className="pmivr-title pt-2">Upload Audio File</div>
                <AudioPlayer filePath={uploadedFileInfo.filePath} cssClass={CSS_CLASSES.AUDIO_BUTTON_LARGE} isUploadedOnGit={true} />
            </div>
            <div className="row d-flex align-items-center">
                <div className="col-md-12 pt-3 pmivr-drop-audio-file">
                    <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.UPLOAD_VOICE_FILE}>
                        <div className="choose-file">
                            Drop audio file here or{" "}
                            <a href="/#">Browse <i className="bi bi-link-45deg"></i></a>
                            <input id="voiceFileUpload" className="form-control form-control-lg" accept=".wav, .mp3, .aiff"
                                onChange={(e) => { voiceFileUploadChangeHandler(e) }}
                                placeholder="Drop audio file here or Browse" type="file" />
                        </div>
                    </PmivrOverlayTrigger>
                    <span className="text-muted">It only accepts WAV, AIFF, and MP3 formats, and the file size must not exceed 100MB.</span>
                </div>
                <div className="pmivr-upload-file">
                    <div className={uiState.isErrorMessage ? "failed-upload" : "success-upload"}>{uiState.infoMessage}</div>
                    <div className="file-name mb-2">File Path : <span className="file-path">{uploadedFileInfo.filePath}</span></div>
                    <div className="file-size">File Size : <span className="file-path"> {uploadedFileInfo.fileSize} {uploadedFileInfo.fileSize !== "" ? "KB" : ""} </span></div>
                </div>
                <button style={{ width: "-webkit-fill-available" }} className="pmivr-btn-secondary m-3 p-3"
                    disabled={uiState.uploadBtnDisable || !uploadedFileInfo.file} onClick={() => uploadFile()}>
                    Upload File
                </button>
            </div>
        </div>
    );
};
export default UploadVoiceFile;
