import { useEffect, useRef, useState } from "react";
import { Accordion } from "react-bootstrap";
import PropTypes from "prop-types";

import VoicePromptSelection from "../../common/voice-prompt-selection/VoicePromptSelection.js";
import PmivrSnackBar from "../../common/dialog/pmivr-snackbar.js";

import { ATTRIBUTES } from "../../../constants/attributes";
import { MESSAGES } from "../../../constants/messages.js";
import { APP_CONFIG } from "../../../config/config.js";
import { VoicePrompt } from "../../../models/voice-file.js";

import ElementService from "../../../services/element.service";
import DiagramService from "../../../services/diagram.service.js";

/**
 * Voice prompts view , with multiple prompts with variables configuration
 * To allow multiple prompts like in case of voice prompt , hangp case etc
 */
const VoicePromptsView = (props) => {
    // array of supported languages
    const [supportedLanguages, setSupportedLanguages] = useState([]);
    // language selected
    const [selectedLanguage, setSelectedLanguage] = useState("en");
    // information regarding voice file info
    const [voiceFileInfo, setVoiceFileInfo] = useState({});
    // key of the currently active accordion item
    const [activeAccordionKey, setActiveAccordionKey] = useState(null);
    const [promptsList, setPromptsList] = useState([]);

    const { element } = props;
    // ref for openeing the success/error message
    const snackbarRef = useRef();

    // set the supported languages configured in wizard
    useEffect(() => {
        const init = async () => {
            const supportedLanguages = await DiagramService.getSupportedLanguages();
            setSupportedLanguages(supportedLanguages);
        }
        init();
    }, []);

    // on language change we need to get the list of updated prompts for that language
    useEffect(() => {
        const init = async () => {
            const voiceFileInfo = await getVoiceFileInfo(element, ATTRIBUTES.VOICE_FILE_INFO);
            setVoiceFileInfo(voiceFileInfo);
        }
        init();
        // on change of element state should get updated
    }, [element]);

    /**
     * Update prompts list when the selected language changes
     */
    useEffect(() => {
        if (Array.isArray(voiceFileInfo[selectedLanguage])) {
            setPromptsList(voiceFileInfo[selectedLanguage]);
        } else {
            const info = voiceFileInfo[selectedLanguage] || {};
            const promptInstance = new VoicePrompt(info);
            setPromptsList([promptInstance]);
        }
    }, [selectedLanguage, voiceFileInfo]);

    /**
     * Gets the voice file info object for the particular element
     * @param {Object} element 
     * @param {string} property 
     * @returns {{en:{{filePath,ttsText,voiceFileType}},es:{filePath,ttsText,voiceFileType}}}
     */
    const getVoiceFileInfo = async (element, property) => {
        const voiceFileInformation = element.businessObject.get(property) ?
            JSON.parse(element.businessObject.get(property))
            : {};
        return voiceFileInformation;
    }

    /**
     * updates the prompt list and also updates the element with the updated lsit
     * @param {[{filePath,ttsText,fileSize,isUploadedOnGit}]} updatedPromptsList 
     */
    const handleUpdatePromptList = (updatedPromptsList) => {
        setPromptsList(updatedPromptsList);
        // Update voice file info state with the latest updated prompt list
        setVoiceFileInfo((prev) => ({ ...prev, [selectedLanguage]: updatedPromptsList }));
    }

    /**
     * Save the updated voice file info for the selected language to the element
     */
    const handleSave = () => {
        try {
            setVoiceFileInfo(voiceFileInfo); // Save changes to the main state
            ElementService.updateElementAttr(element, ATTRIBUTES.VOICE_FILE_INFO, JSON.stringify(voiceFileInfo));
            // snackbar message : saved successfully
            snackbarRef.current.open(MESSAGES.SAVED_SUCCESSFULLY);
            setTimeout(() => { DiagramService.closeRightPanel() }, APP_CONFIG.MESSAGE_TIMEOUT);
        } catch (error) {
            snackbarRef.current.open(MESSAGES.ERR.SOMETHING_WENT_WRONG);
        }
    };

    return (
        <>
            <PmivrSnackBar ref={snackbarRef} />
            <Accordion activeKey={activeAccordionKey} className="mt-3 pmivr-accordion"
                onSelect={(selectedKey) => setActiveAccordionKey(selectedKey)} flush>
                {
                    [...supportedLanguages].map((language) => {
                        return (
                            <div key={language}>
                                <Accordion.Item eventKey={language} className="mt-3 accordion-voice-item">
                                    <Accordion.Header onClick={() => setSelectedLanguage(language)}>
                                        <span className="pmivr-accordian-tab">
                                            Language : {language}
                                        </span>
                                    </Accordion.Header>
                                    <Accordion.Body className="pmivr-card border-none p-0 mt-2">
                                        <VoicePromptSelection selectedLanguage={selectedLanguage}
                                            promptsList={promptsList} onChange={handleUpdatePromptList} />
                                    </Accordion.Body>
                                </Accordion.Item>
                            </div>
                        )
                    })
                }
            </Accordion>
            <div className="pt-1 w-100 bg-white position-fixed position-bottom" style={{ zIndex: "9" }}>
                <button type="button" className="pmivr-btn-cancel me-2" onClick={DiagramService.closeRightPanel}>
                    Cancel
                </button>
                <button type="button" className="pmivr-btn-app" onClick={handleSave}>
                    Save
                </button>
            </div>
        </>
    );
}

VoicePromptsView.propTypes = {
    element: PropTypes.object,
}

export default VoicePromptsView;