import { useContext, useRef, useState } from 'react';

import { ATTRIBUTES } from "../../../constants/attributes";
import { TAB_LIST } from "../../../constants/element";
import { MESSAGES, TOOLTIP } from '../../../constants/messages';
import { APP_CONFIG } from '../../../config/config';
import { VoiceContext } from '../../../contexts/app-context';

import PmivrOverlayTrigger from '../../common/overlay-trigger/pmivr-overlay-trigger';
import PmivrLabel from '../../common/label/pmivr-label';
import PmivrSnackBar from '../../common/dialog/pmivr-snackbar';

import ElementService from '../../../services/element.service';
import DiagramService from '../../../services/diagram.service';

/**
 * Voice record start task view in the diagram
 */
const VoiceRecordStartPropsView = () => {
    const { element, tabType } = useContext(VoiceContext);
    const snackbarRef = useRef();
    /**
     * ui state for changing the state of save button
     * `isPaymentAuthRecording`: Boolean value indicating whether payment authorization voice recording is enabled.
     *  `audioFormat`: String representing the selected audio format for voice recording.
     * `disabled`: Boolean used to enable/disable the Save button based on form changes.
     */
    const [uiState, setUiState] = useState({
        isPaymentAuthRecording: element.businessObject.get(ATTRIBUTES.IS_PAYMENT_AUTH_VOICE_RECORD_START) || false,
        audioFormat: element.businessObject.get(ATTRIBUTES.VOICE_RECORD_START_AUDIO_FORMAT) || "", disabled: true
    });

    /**
     * Update the property in the flow
     * @param {string} property
     * @param {Object} value
     */
    const updateProperty = (property, value) => {
        ElementService.updateElement(element, property, value);
    }
    /**
     * Function to handle the Save action when the Save button is clicked.
     * Updates the properties in the business object and handles UI feedback.
     */
    const handleSave = () => {
        try {
            updateProperty(ATTRIBUTES.IS_PAYMENT_AUTH_VOICE_RECORD_START, uiState.isPaymentAuthRecording);
            updateProperty(ATTRIBUTES.VOICE_RECORD_START_AUDIO_FORMAT, uiState.audioFormat);
            setUiState({ ...uiState, disabled: true });
            // snackbar message : saved successfully
            snackbarRef.current.open(MESSAGES.SAVED_SUCCESSFULLY);
            setTimeout(() => { DiagramService.closeRightPanel() }, APP_CONFIG.MESSAGE_TIMEOUT);
        } catch (error) {
            snackbarRef?.current?.open(MESSAGES.ERR.SOMETHING_WENT_WRONG);
        }
    }

    return (
        <>
            <PmivrSnackBar ref={snackbarRef} />
            {
                (tabType === TAB_LIST)
                    ? <button
                        className="nav-link active"
                        id="transfer"
                        data-bs-toggle="tab"
                        data-bs-target="#edit-voice-record"
                        type="button"
                        role="tab"
                        aria-controls="edit-voice-record"
                        aria-selected="false"
                    >
                        Monitor Voice Start
                    </button>
                    : <>
                        <div className="tab-pane fade show active" id="edit-voice-record" role="tabpanel" aria-labelledby="nav-home-tab">
                            <div className=" m-2 mt-3">
                                <div className="form-check pmivr-check-radio form-check-inline mb-3 mt-1">
                                    <input className="form-check-input" type="checkbox" id="is-payment-auth-recording"
                                        checked={uiState.isPaymentAuthRecording}
                                        onChange={(event) => { setUiState({ ...uiState, isPaymentAuthRecording: event.target.checked, disabled: uiState.audioFormat ? false : true }) }}
                                    />
                                    <label className="form-check-label">Is Payment Auth Recording</label>
                                </div>
                                <div className="form-group mb-3">
                                    <PmivrLabel label="Enter Audio Format" tooltip={TOOLTIP.INFO.AUDIO_FORMAT} />
                                    <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.AUDIO_FORMAT}>
                                        <input
                                            id="MonitorVoiceAudioFormat"
                                            name="MonitorVoiceAudioFormat"
                                            className="form-control pmivr-input"
                                            value={uiState.audioFormat}
                                            onChange={(event) => { setUiState({ ...uiState, audioFormat: event.target.value, disabled: event.target.value.trim() === "" }); }}
                                        />
                                    </PmivrOverlayTrigger>
                                </div>
                            </div>
                        </div>
                        <div className="pt-1 w-100 bg-white position-fixed position-bottom">
                            <button type="button" className="pmivr-btn-cancel me-2" onClick={DiagramService.closeRightPanel}>
                                Cancel
                            </button>
                            <button type="button" className="pmivr-btn-app" disabled={uiState.disabled} onClick={handleSave}>
                                Save
                            </button>
                        </div>
                    </>
            }
        </>
    )
}

export default VoiceRecordStartPropsView;