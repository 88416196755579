import * as Yup from "yup";

/**
 * Validation schema for the form fields using Yup.
 */
export const APICallValidationSchema = Yup.object({
    apiUrl: Yup.string().required("API URL is required"),
    /**
     * @property {string} apiPayload - Payload for API requests; required if the method is POST or PUT.
     */
    apiPayload: Yup.string().when("apiMethod", {
        is: (method) => ["POST", "PUT"].includes(method),
        then: Yup.string().required("Payload is required for POST or PUT requests")
    }),
    /**
     * @property {Array<string>} responseVariables - Array of response variable chips; at least one is required.
     */
    responseVariables: Yup.array().min(1, 'At least one response variable is required').of(Yup.string().required('Response variable cannot be empty')),
    /**
     * @property {string} authUrl - Authentication URL; conditionally required if any related fields have a value.
     */
    authUrl: Yup.string().test(
        "authUrlRequired",
        "Please Enter Auth URL",
        function (value) {
            const { authResponseVariable, authPayload, apiPayload, authParams } = this.parent;
            // Check if any field has a value
            const isAnyFieldFilled = [authResponseVariable, authPayload, apiPayload, authParams].some(Boolean);
            return isAnyFieldFilled ? !!value : true; // Require `authUrl` if any field is filled
        }
    ),
    /**
     * @property {string} authResponseVariable - Authentication response variable; conditionally required if any related fields have a value.
     */
    authResponseVariable: Yup.string().test(
        "authResponseVariableRequired",
        "Plase Enter Auth Response Variable",
        function (value) {
            const { authUrl, authPayload, apiPayload, authParams } = this.parent;
            // Check if any field has a value
            const isAnyFieldFilled = [authUrl, authPayload, apiPayload, authParams].some(Boolean);
            return isAnyFieldFilled ? !!value : true; // Require `authResponseVariable` if any field is filled
        }
    ),
    authPayload: Yup.string().when("authMethod", {
        is: (method) => ["POST", "PUT"].includes(method),
        then: Yup.string().required("Payload is required for POST or PUT requests")
    }),
});