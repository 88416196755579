import React, { useState } from 'react'

import { TOOLTIP } from '../../../constants/messages';

import PmivrTooltip, { TOOLTIP_POSITIONS } from '../../../components/common/tooltip/pmivr-tooltip';
import PmivrSearchInput from '../../../components/common/search-input/pmivr-search';
import PmivrLabel from '../../../components/common/label/pmivr-label';
import PmivrSelectOption from '../../../components/common/select-option/pmivr-select';

import VoiceFileManagerService from '../../../services/voice-file-manager.service';

const VoiceFileList = (props) => {
    const { handleUiState, setUiState, uiState, setVoiceFileActionTitle, setSelectedDetails, voiceFileList, setVoiceFileList,
        setFilePath, setVoiceFileInfo, businessCode, environment, setActionType
    } = props;

    const [searchedBusinessCode, setSearchedBusinessCode] = useState(businessCode || "");
    const [searchedEnvironment, setSearchedEnvironment] = useState(environment || "");

    // Options for environment that can be selected from filter dropdown
    const environmentOptions = [
        { value: "DCS1", label: "DCS1" },
        { value: "DCS2", label: "DCS2" },
        { value: "DCS4", label: "DCS4" },
        { value: "DCS5", label: "DCS5" },
    ];

    /**
     * Handles the display of the voice file settings menu when a specific file is selected.
     * This function updates the file path in the state, sets the voice file information,
     * and shows the voice file settings menu.
     * 
     * @function handleVoiceFileSettingMenu
     * @param {Event} event - The event object representing the user interaction (e.g., a click event).
     * @param {string} filePath - The file path of the selected voice file.
     * @returns {Promise<void>} - A promise that resolves once the UI state is updated and the menu is shown.
     */
    const handleVoiceFileSettingMenu = async (event, filePath) => {
        // Prevent the event from propagating to parent elements
        event.stopPropagation();
        setFilePath(filePath);
        setVoiceFileInfo({ filePath: filePath });
        // Show the voice file setting menu
        setUiState({ ...uiState, showVoiceFileSettingMenu: true });
    };

    /**
     * Handles the edit action when the user clicks on an item in the voice file list.
     * It updates the selected details (action key and file path) to be used in editing the voice file.
     *
     * @function handleEditClick
     * @param {string} key - The action key associated with the selected voice file.
     * @param {string} filePath - The file path of the voice file to be edited.
     * @param {string} languageCode - The language code of the voice file
     * @returns {void}
     */
    const handleEditClick = (key, filePath, languageCode, index) => {
        setSelectedDetails({ actionKey: key, filePath, languageCode, index });
        if (!key && !filePath && !languageCode) {
            setActionType("add");
        } else {
            setActionType("edit");
        }
    };

    /**
     * Handles the search functionality for voice files by capturing the input value and filtering the table accordingly.
     * @param {string} searchValue - Search value entered in the input box
     * @returns {void}
     */
    const searchVoiceFiles = async (searchValue = searchedBusinessCode) => {
        setSearchedBusinessCode(searchValue);
        const response = await VoiceFileManagerService.getMessageConfigByAppCodes(searchValue, "inbound-agi-server", searchedEnvironment);
        setVoiceFileList(response);
    }

    /**
     * Handles the environment filter functionality for voice files by capturing the selected value and filtering the table accordingly.
     * @param {Event} event - Selected value from the dropdown
     * @returns {void}
     */
    const handleEnvironmentChange = async (event) => {
        const value = event.target.value;
        setSearchedEnvironment(value);
        const response = await VoiceFileManagerService.getMessageConfigByAppCodes(searchedBusinessCode, "inbound-agi-server", value);
        setVoiceFileList(response);
    };

    return (
        <div className='ps-2 pe-2 pe-0'>
            <div className="row">
                <div className='col-4'>
                    <PmivrSearchInput
                        value={searchedBusinessCode}
                        placeholder="Search business code / tla"
                        onSearch={(searchValue) => searchVoiceFiles(searchValue)}
                        onEnter={searchVoiceFiles}
                        label="Search Business Code / TLA"
                        tooltip="Search Business Code / TLA"
                    />
                </div>
                <div className='col-4'>
                    <PmivrLabel label="Environment" tooltip="Select the environment for voice files" />
                    <PmivrSelectOption
                        selectValue={searchedEnvironment}
                        onChange={handleEnvironmentChange}
                        firstOption="Select Environment"
                        options={environmentOptions}
                        tooltip="Select Environment"
                    />
                </div>
            </div>
            <div className="pt-2">
                <div className='row d-flex justify-content-between align-items-center'>
                    <div className='col-3'>
                        <h5 className='pt-2'>Voice File Lists</h5>
                    </div>
                    <div className='col-auto'>
                        <button className='btn pmivr-btn-app' onClick={() => {
                            handleUiState(true);
                            handleEditClick("", "", "");
                            setVoiceFileActionTitle("Add Voice File")
                        }}>
                            + Add new
                        </button>
                    </div>
                </div>
                <table className="table pmivr-table header-fixed border mt-2 voice-file-manager-table" id="main-table">
                    <thead className='border-bottom'>
                        <tr>
                            <th width="5%" scope="col">#</th>
                            <th width="20%" scope="col" className="text-center">Action key</th>
                            <th width="45%" scope="col" className="text-center">Value</th>
                            <th width="10%" scope="col" className="text-center">Language</th>
                            <th width="10%" scope="col" className="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="pmivr-scroll" style={{ borderStyle: "none", height: "350px" }}>
                        {voiceFileList?.length > 0 ? (
                            voiceFileList.map((file, index) => (
                                <tr key={file.actionKey}>
                                    <th width="5%" scope="row">{index + 1}</th>
                                    <td width="20%" className="text-truncate cursor-pointer">
                                        {file.actionKey}
                                    </td>
                                    <td width="45%" className="text-truncate cursor-pointer" style={{ maxWidth: '200px' }}>
                                        {file.actionMessage ?
                                            <PmivrTooltip message={file.actionMessage}>
                                                <span>{file.actionMessage}</span>
                                            </PmivrTooltip> :
                                            "-"
                                        }
                                    </td>
                                    <td width="10%">{file.languageCode}</td>
                                    <td width="10%">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <span className='pe-2 cursor-pointer'>
                                                <PmivrTooltip message="Edit" position={TOOLTIP_POSITIONS.TOP}>
                                                    <i className="bi bi-pencil-square fa-lg icon-font-size play-button"
                                                        onClick={(e) => {
                                                            handleUiState(true);
                                                            handleEditClick(file.actionKey, file.actionMessage, file.languageCode, index);
                                                            handleVoiceFileSettingMenu(e, file.actionMessage);
                                                            setVoiceFileActionTitle("Edit Voice File")
                                                        }}></i>
                                                </PmivrTooltip>
                                            </span>
                                            <span className='pe-2 cursor-pointer'>
                                                <PmivrTooltip message={TOOLTIP.DOWNLOAD_FILE} position={TOOLTIP_POSITIONS.TOP}>
                                                    <i className="bi bi-cloud-arrow-down fa-lg icon-font-size play-button fs-4 align-middle"></i>
                                                </PmivrTooltip>
                                            </span>
                                            <span className='pe-2 cursor-pointer'>
                                                <PmivrTooltip message={TOOLTIP.DELETE} position={TOOLTIP_POSITIONS.TOP}>
                                                    <i className="bi bi-trash fa-lg  icon-font-size play-button"></i>
                                                </PmivrTooltip>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr><td>No Voice Files</td></tr>
                        )}
                    </tbody>
                </table>
            </div >
        </div >
    )
}

export default VoiceFileList;