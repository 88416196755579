import React, { useEffect, useState } from "react";

import { VOICE_FILE_UPLOAD_TYPE } from "../../../constants/voice-file";
import { TOOLTIP } from "../../../constants/messages";
import { CSS_CLASSES } from "../../../constants/css-classes";
import { envConfig } from "../../../environment";

import PmivrOverlayTrigger from "../../../components/common/overlay-trigger/pmivr-overlay-trigger";
import PmivrLabel from "../../../components/common/label/pmivr-label";
import AudioPlayer from "../../../components/common/audio-player/audio-player";
import UploadVoiceFile from "./upload-voice-file";
import TextToSpeech from "./text-to-speech";
import PmivrSelectOption from "../../../components/common/select-option/pmivr-select";

/**
 * The LibraryVoiceFile component handles the management of voice files, allowing users to select, upload,
 * or generate voice files using different methods (Library, Text to Speech, or File Upload).
 * It provides functionality for selecting a language, specifying the action key, and uploading or editing voice files.
 * The component manages the UI for voice file selection and uploading, including the ability to switch between 
 * different upload methods, display the appropriate file-related information, and handle language and action key changes.
 * @param {object} props - The properties passed to the component.
 * @param {string} props.voiceFileActionTitle - The title of the voice file action (e.g., "Manage Voice File").
 * @param {object} props.selectedDetails - Contains the details of the currently selected voice file (actionKey and filePath).
 * @param {function} props.setSelectedDetails - Function to update the selectedDetails state.
 * @param {object} props.voiceFileInfo - Contains information about the voice file (filePath, ttsText, etc.).
 * @param {string} props.actionKey - The current action key associated with the selected voice file.
 * @param {function} props.setActionKey - Function to update the action key state.
 * @param {string} props.filePath - The current file path of the selected voice file.
 * @param {function} props.setFilePath - Function to update the file path state.
 *
 * @returns {JSX.Element} The rendered ManageVoiceFile component.
 */
const ManageVoiceFile = (props) => {
    const { voiceFileActionTitle, selectedDetails, setSelectedDetails, voiceFileInfo, actionKey, setActionKey, filePath, setFilePath, languageCode, setLanguageCode } = props;
    const voiceFilePath = ""; // Holds the file path of the selected voice file
    // State for handling the upload type (Library, TTS, or Upload)
    const [uploadVoiceFileType, setUploadVoiceFileType] = useState(VOICE_FILE_UPLOAD_TYPE.LIBRARY);
    // State for tracking the selected language (default is English)
    const [selectedLanguage, setSelectedLanguage] = useState(selectedDetails.languageCode || 'en');

    const languageOptions = [
        { value: "en", label: "English (en)" },
        { value: "es", label: "Spanish (es)" },
        { value: "fr", label: "French (fr)" },
        { value: "de", label: "German (de)" },
    ];

    // Update the input fields when new props are received
    useEffect(() => {
        if (selectedDetails) {
            setActionKey(selectedDetails.actionKey);
            setFilePath(selectedDetails.filePath);
            setLanguageCode(selectedDetails.languageCode);
            setSelectedLanguage(selectedDetails.languageCode);
        }
    }, [selectedDetails]);

    /**
     * Handles the language change event from a dropdown selection.
     * @param {Event} event - The event object from the language change event. 
     */
    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);
        setLanguageCode(event.target.value);
    }

    return (
        <div>
            <h5 className='border-bottom p-3 ps-3'>{voiceFileActionTitle}</h5>
            <div className="pmivr-scroll" style={{ height: "70vh", overflowY: "auto", overflowX: "hidden" }}>
                <div className='px-3 pb-3'>
                    <div className="pb-2">
                        <PmivrLabel label="Action Key" tooltip="Action Key" />
                        <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.ACTION_KEY}>
                            <input type="text" id="actionKey"
                                name="actionKey"
                                className="form-group form-control pmivr-input "
                                placeholder={`Enter action key`}
                                value={actionKey}
                                onChange={(e) => setActionKey(e.target.value)} />
                        </PmivrOverlayTrigger>
                    </div>
                    <PmivrLabel label="Language" tooltip="Language" />
                    <PmivrSelectOption
                        selectValue={selectedLanguage}
                        onChange={handleLanguageChange}
                        cssClass=""
                        firstOption="Select Language"
                        options={languageOptions}
                        tooltip={TOOLTIP.INPUT.TTS_LANGUAGE}
                    />
                </div>
                <ul className="nav nav-pills pmivr-rounded-circle-tabs text-center d-flex mb-2 mx-3" id="pills-tab" role="tablist">
                    <li className="nav-item flex-grow-1" role="presentation">
                        <button id="filePath-tab" data-bs-toggle="tooltip" data-bs-placement="top" title="Add file path" type="button" role="tab"
                            aria-controls="file-path" aria-selected={uploadVoiceFileType === VOICE_FILE_UPLOAD_TYPE.LIBRARY}
                            className={
                                uploadVoiceFileType === VOICE_FILE_UPLOAD_TYPE.LIBRARY
                                    ? "nav-link active d-inline w-100"
                                    : "nav-link d-inline w-100"
                            }
                            onClick={() => setUploadVoiceFileType(VOICE_FILE_UPLOAD_TYPE.LIBRARY)} >
                            <i className="bi bi-file-earmark-music"></i>
                            <p>Library File</p>
                        </button>
                    </li>
                    <li className="nav-item flex-grow-1" role="presentation">
                        <button id="tts-file-tab" data-bs-toggle="tooltip" data-bs-placement="top" title="Text to speech" type="button" role="tab"
                            aria-controls="tts-voice-file" aria-selected={uploadVoiceFileType === VOICE_FILE_UPLOAD_TYPE.TTS}
                            className={
                                uploadVoiceFileType === VOICE_FILE_UPLOAD_TYPE.TTS
                                    ? "nav-link active d-inline w-100"
                                    : "nav-link d-inline w-100"
                            }
                            onClick={() => { setUploadVoiceFileType(VOICE_FILE_UPLOAD_TYPE.TTS); setFilePath("") }} >
                            <i className="bi bi-chat-square-text"></i>
                            <p>Text To Speech</p>
                        </button>
                    </li>
                    <li className="nav-item flex-grow-1" role="presentation">
                        <button id="uploadFile-tab" data-bs-toggle="tooltip" data-bs-placement="top" title="Upload voice file" type="button" role="tab"
                            aria-controls="upload-file" aria-selected={uploadVoiceFileType === VOICE_FILE_UPLOAD_TYPE.UPLOAD}
                            className={
                                uploadVoiceFileType === VOICE_FILE_UPLOAD_TYPE.UPLOAD
                                    ? "nav-link active d-inline w-100"
                                    : "nav-link d-inline w-100"
                            }
                            onClick={() => { setUploadVoiceFileType(VOICE_FILE_UPLOAD_TYPE.UPLOAD); setFilePath(""); }} >
                            <i className="bi bi-upload"></i>
                            <p>Upload File</p>
                        </button>
                    </li>
                </ul>
                <div className="tab-content mx-3 mb-3" id="pills-tabContent">
                    {uploadVoiceFileType === VOICE_FILE_UPLOAD_TYPE.LIBRARY && (
                        <div id="file-path" role="tabpanel" aria-labelledby="filePath-tab" className="tab-pane fade show active">
                            <div className="d-flex justify-content-between">
                                <div className="pmivr-title pt-2">Library Audio File</div>
                                <AudioPlayer filePath={voiceFilePath} cssClass={CSS_CLASSES.AUDIO_BUTTON_LARGE}
                                    isUploadedOnGit={true}></AudioPlayer>
                            </div>
                            <div className="form-group custom-input mb-3">
                                <PmivrLabel label="Base File path" tooltip={TOOLTIP.INPUT.BASE_FILE_PATH} cssClass="pb-2" />
                                <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.BASE_FILE_PATH}>
                                    <input disabled type="text" className="form-control pmivr-input pmivr-disabled"
                                        size="50"
                                        value={`${envConfig.REACT_APP_DEFAULT_VOICE_FILE_PATH}`} />
                                </PmivrOverlayTrigger>
                            </div>
                            <div className="form-group custom-input">
                                {/* value={filePath}
                                onChange={(e) => setFilePath(e.target.value)} */}
                                <PmivrLabel label="File path (File name without .wav extension)" tooltip={TOOLTIP.VOICE_TYPE_PATH_INFO} cssClass="pb-2" />
                                <PmivrOverlayTrigger tooltip={TOOLTIP.FILE_PATH_INFO}>
                                    {/* <VoiceFilesSelect onSelect={(value) => updateVoiceFilePathState(value, { filePath: filePath, voiceFileType: VOICE_FILE_UPLOAD_TYPE.LIBRARY, fileName: "abc" })}
                                        selectedItem={voiceFilePath} /> */}
                                    <input type="text"
                                        id="filePath"
                                        name="actionKey"
                                        className="form-group form-control pmivr-input "
                                        placeholder={`Enter Voice File Name`}
                                        value={filePath}
                                        onChange={(e) => setFilePath(e.target.value)} />
                                </PmivrOverlayTrigger>
                            </div>
                        </div>
                    )}
                    {uploadVoiceFileType === VOICE_FILE_UPLOAD_TYPE.TTS && (
                        <div id="tts-voice-file" role="tabpanel" aria-labelledby="tts-file-tab" className={`tab-pane fade show active`}>
                            <TextToSpeech setFilePath={setFilePath} selectedLanguage={selectedLanguage} />
                        </div>
                    )}
                    {uploadVoiceFileType === VOICE_FILE_UPLOAD_TYPE.UPLOAD && (
                        <div id="upload-file" role="tabpanel" aria-labelledby="uploadFile-tab" className="tab-pane fade show active">
                            <UploadVoiceFile voiceFileInfo={voiceFileInfo} setFilePath={setFilePath} filePath={filePath} selectedLanguage={selectedLanguage} />
                        </div>
                    )}
                </div>
            </div>
        </div >
    );
};
export default ManageVoiceFile;