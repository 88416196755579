import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { ATTRIBUTES } from "../../../../constants/attributes";
import { MESSAGES, TOOLTIP } from "../../../../constants/messages";
import { APP_CONFIG } from "../../../../config/config";
import { VoiceContext } from "../../../../contexts/app-context";
import { SERVICE_TYPES } from "../../../../constants/task-types";

import PmivrOverlayTrigger from "../../../common/overlay-trigger/pmivr-overlay-trigger";
import PmivrLabel from "../../../common/label/pmivr-label";
import PmivrSnackBar from "../../../common/dialog/pmivr-snackbar";

import ElementService from "../../../../services/element.service";
import DiagramService from "../../../../services/diagram.service";

/**
 * Component for configuring agi method call service properties.
 * @returns {React.Component} Html element to render
 */
const MethodCallServiceView = () => {
  const snackbarRef = useRef();
  const { element } = useContext(VoiceContext);
  // array of agi methods configured
  const [agiMethods, setAgiMethods] = useState([]);
  // selected agi method description
  const [selectedMethodDesc, setSelectedMethodDesc] = useState("");
  const [uiState, setUiState] = useState({ agiMethod: '', responseVariable: '', disabled: true });
  // getting latest value of agi service methods from redux
  const { agiServiceMethods } = useSelector(state => state.config);

  useEffect(() => {
    // loads the agi methods configured
    const init = async () => {
      const firstElement = { "name": MESSAGES.SELECT_STRING, "description": "", "_id": "0" };
      const methodList = [firstElement, ...agiServiceMethods];
      setAgiMethods(methodList);
      // populate data only when method call is selected
      if (ElementService.getAttribute(element, ATTRIBUTES.SERVICE.TYPE, SERVICE_TYPES.METHOD_CALL) === SERVICE_TYPES.METHOD_CALL) {
        setUiState({
          ...uiState,
          agiMethod: getAgiMethodName(),
          responseVariable: ElementService.getAttribute(element, ATTRIBUTES.SERVICE.RESPONSE_VARIABLES, "")
        });
      }
    };
    init();
    // element in dependency array updates the state of method call service
  }, [element]);

  // when agiMethods get loaded then set description 
  useEffect(() => {
    // on load of dropdown of methods set the selected method's description
    const methodName = getAgiMethodName();
    setMethodDescription(methodName);
  }, [agiMethods]);

  /**
   * Update a Method Call attribute in the element.
   * @param {string} attributeName - The name of the attribute to update.
   * @param {string} value - The new value to set for the attribute.
   */
  const updateAttribute = (attributeName, value) => {
    ElementService.updateElement(element, attributeName, value);
  };


  /**
   * Sets the description to show on hover for selected method
   * @param {string} methodName 
   */
  const setMethodDescription = (methodName) => {
    const agiMethod = agiMethods.find((method) => method.name === methodName);
    setSelectedMethodDesc(agiMethod?.description);
  }

  /**
   * Get the method name only from prefixed string as did in setAgiMethodAttribute
   * eg from this "${environment.services.populateDataFromLiability}"; get populateDataFromLiability
   */
  const getAgiMethodName = () => {
    const attributeValue = ElementService.getAttribute(element, ATTRIBUTES.SERVICE_IMPLEMENTATION_METHOD, "")
    let methodName = "";
    if (attributeValue) {
      const segments = attributeValue.split('.');
      const lastSegment = segments[segments.length - 1];
      // from last segment trim last char }
      methodName = lastSegment.split("}")[0];
    }
    return methodName;
  };

  /**
   * Updates the `uiState` with the provided field and value.
   * @param {string} field - The name of the field to update in `uiState`.
   * @param {string} value - The value to set for the specified field.
   */
  const handleInputChange = (field, value) => {
    setUiState((prevState) => ({
      ...prevState, [field]: value,
      // Update the `disabled` state to control the Save button.
      // `disabled` is true if `agiMethod` is empty.
      disabled: !(field === "agiMethod" ? value : prevState.agiMethod)
    }));
  };

  /**
   * Saves the updated attributes to the element.
   * Updates the AGI method attribute, response variable, and service type.
   */
  const handleSave = () => {
    const attrValue = "${environment.services." + uiState.agiMethod + "}";
    updateAttribute(ATTRIBUTES.SERVICE_IMPLEMENTATION_METHOD, attrValue);
    updateAttribute(ATTRIBUTES.SERVICE.RESPONSE_VARIABLES, uiState.responseVariable);
    updateAttribute(ATTRIBUTES.SERVICE.TYPE, SERVICE_TYPES.METHOD_CALL);
    setUiState({ ...uiState, disabled: true });
    // snackbar message : saved successfully
    snackbarRef.current.open(MESSAGES.SAVED_SUCCESSFULLY);
    setTimeout(() => { DiagramService.closeRightPanel() }, APP_CONFIG.MESSAGE_TIMEOUT);
  };

  return (
    <div className=" m-2 mt-3">
      <PmivrSnackBar ref={snackbarRef} />
      {/* agi service method */}
      <div className="form-group mb-3">
        <PmivrLabel label="Select AGI Method Name" tooltip={TOOLTIP.INFO.SERVICE_TASK_METHOD_NAME} />
        <PmivrOverlayTrigger tooltip={selectedMethodDesc}>
          <select id="implementation" name="implementation" className="pmivr-select"
            value={uiState.agiMethod || MESSAGES.SELECT_STRING}
            onChange={(event) => { handleInputChange('agiMethod', event.target.value); setMethodDescription(event.target.value); }}
          >
            {agiMethods.map((agiMethod, index) => (
              <option key={index} value={agiMethod.name} disabled={agiMethod.name === MESSAGES.SELECT_STRING}>
                {agiMethod.name}
              </option>
            ))}
          </select>
        </PmivrOverlayTrigger>
      </div>

      {/* response variable */}
      <div className="form-group mb-3">
        <PmivrLabel label="Enter Response Variable" tooltip={TOOLTIP.INFO.SERVICE_IMPL_METHOD_RESPONSE_VARIABLE} />
        <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.SERVICE_IMPL_RESPONSE_VARIABLE}>
          <input id="responseVariable" name="responseVariable" className="form-control pmivr-input" placeholder="Enter Variable"
            value={uiState.responseVariable}
            onChange={(event) => { handleInputChange('responseVariable', event.target.value); }}
          />
        </PmivrOverlayTrigger>
      </div>
      <div className="pt-1 w-100 bg-white position-fixed position-bottom" >
        <button type="button" className="pmivr-btn-cancel me-2" onClick={DiagramService.closeRightPanel}>
          Cancel
        </button>
        <button type="button" className="pmivr-btn-app" disabled={uiState.disabled} onClick={handleSave}>
          Save
        </button>
      </div>
    </div >
  );
};

export default MethodCallServiceView;