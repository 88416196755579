import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import VoicePromptSelection from '../voice-prompt-selection/VoicePromptSelection';

/**
 * Component to render tab with languages configured and multiprompt selection for voice files like zero out
 * @param {onSave,voicePromptInfo,langages,showAddPromptBtn,showVariableOption} props props data from parent component
 * @returns {React.Component} Html code to render call end voice files
 */
const VoicePromptTab = (props) => {
    const { voicePromptInfo, onSave, languages, showAddPromptBtn = true, showVariableOption = true, businessCode = "", dirName = "" } = props;
    // flag to set the selected language
    const [selectedLanguage, setSelectedLanguage] = useState("");

    // reset the language as well, otherwise it will show previous selected language
    useEffect(() => {
        setSelectedLanguage(languages[0]);
    }, [languages]);

    /**
     * Update the whole voice file info in the with prompt list in wizard values
     * @param {{filePath,voiceFileType}} updatedPromptsList 
     * @param {string} selectedLanguage 
     */
    const handleSavePromptList = (updatedPromptsList, selectedLanguage) => {
        const updatedVoiceFileInfo = { ...voicePromptInfo, [selectedLanguage]: updatedPromptsList };
        onSave(updatedVoiceFileInfo);
    }

    return (
        <div className="wizard-prompt" style={{ maxHeight: '694px', overflowY: 'auto' }}>
            <div>
                <ul className="nav nav-tabs text-center" role="tablist">
                    {
                        languages.map((language) => {
                            return (
                                <li className="nav-item  px-2" role="presentation">
                                    <button className={selectedLanguage === language ? "nav-link active " : "nav-link"}
                                        data-bs-target="#language-options" type="button" role="tab" title={language}
                                        aria-controls="language-options"
                                        onClick={() => setSelectedLanguage(language)} style={{ width: '100%' }}>
                                        {language}</button>
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
            <div className="mt-3" id="language-options" style={{ width: '99%' }}>
                <div className="tab-content" id="pills-tabContent">
                    <VoicePromptSelection selectedLanguage={selectedLanguage}
                        promptsList={voicePromptInfo[selectedLanguage] || []}
                        onChange={(updatedPromptsList) => handleSavePromptList(updatedPromptsList, selectedLanguage)}
                        showAddPromptBtn={showAddPromptBtn} showVariableOption={showVariableOption}
                        businessCode={businessCode} dirName={dirName} />
                </div>
            </div>
        </div>
    );
};

VoicePromptTab.propTypes = {
    // array of voice file prompts for zero out voice files
    voicePromptInfo: PropTypes.arrayOf(
        PropTypes.shape({
            filePath: PropTypes.string,
            ttsText: PropTypes.string,
            playSpeed: PropTypes.number,
            voiceFileType: PropTypes.string,
        })
    ),
    // function to save the call end prompt list 
    onSave: PropTypes.func,
    // title to display on accordion
    languages: PropTypes.array,
    businessCode: "",
    dirName: "",
    // to show add prompt btn or not
    showAddPromptBtn: PropTypes.bool,
    // to disable the variable play in case of speech input we do not have variable play
    showVariableOption: PropTypes.bool
};

VoicePromptTab.defaultProps = {
    showAddPromptBtn: true,
    showVariableOption: true
};

export default VoicePromptTab;