import { DATA_TYPE } from "../constants/data-types";

/**
 * Utility for condition operations
 */
class ConditionUtil {
  /**
   * return the value according to selected data type
   * @param {Object} condition Condition info
   * @param {string} variableType // Type of variable
   */
  static getValueOfConditionByType(variableType, condition) {
    switch (variableType) {
      case DATA_TYPE.BOOLEAN:
        return condition.value;
      case DATA_TYPE.VARIABLE:
        return `this.environment.variables.ivrVar.${condition.value}`;
      case DATA_TYPE.DATE:
        return (condition.isDateOfCodeExecution) ? `new Date()` : `new Date("${condition.value}")`;
      case DATA_TYPE.NUMBER:
        return condition.value;
      case DATA_TYPE.STRING:
        return `"${condition.value}"`;
      default:
        return "";
    }
  }

  /**
  * Construct the condition as per condition Info
  * @param {Object} conditionInfo 
  * @returns {string}
  */
  static constructCondition(conditionInfo) {
    let strCondition;
    const variable = conditionInfo.variable;
    const operator = conditionInfo.operator;
    const jsExpression = conditionInfo.jsExpression;
    const variableType = conditionInfo.selectedVariableType;

    if (operator === "IS_DEFINED") {
      strCondition = `this.environment.variables.ivrVar.${variable}`;
    } else if (operator === "NOT_DEFINED") {
      strCondition = `!this.environment.variables.ivrVar.${variable}`;
    } else if (operator === "JS_EXPRESSION") {
      // if safety check is applied in the expression e.g. ?.split(..).includes(...)
      if (jsExpression.startsWith("?")) {
        strCondition = `this.environment.variables.ivrVar.${variable}${jsExpression}`;
      } else {
        // To Handle negation in js expression e.g. !(.split(..).includes(...))
        const firstDotIndex = jsExpression.indexOf(".");
        strCondition = `${jsExpression.substring(0, firstDotIndex)}this.environment.variables.ivrVar.${variable}${jsExpression.substring(firstDotIndex)}`;
      }
    } else {
      const value = ConditionUtil.getValueOfConditionByType(variableType, conditionInfo);
      if (variableType === DATA_TYPE.DATE) {
        // in case of variable type date, we need to convert the value to date, and then compare
        strCondition = `new Date(this.environment.variables.ivrVar.${variable})  ${operator} ${value}`;
      } else {
        // if normal variable, then compare directly
        strCondition = `this.environment.variables.ivrVar.${variable} ${operator} ${value}`;
      }
    }
    return strCondition;
  }
}

export default ConditionUtil;