import { useContext, useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import PropTypes from 'prop-types';

import PmivrTooltip from "../../common/tooltip/pmivr-tooltip";

import { ACCORDION } from "../../../constants/field-types";
import { APP_CONFIG, DEFAULT_USER_OPTION_INPUT_TIMEOUT } from "../../../config/config";
import { ATTRIBUTES } from "../../../constants/attributes";
import { TASK_TYPE } from "../../../constants/task-types";
import { TOOLTIP } from "../../../constants/messages";
import { TASK_ICONS } from "../../../constants/css-classes";
import { VoiceContext } from "../../../contexts/app-context";
import PmivrLabel from "../../common/label/pmivr-label";
import PmivrOverlayTrigger from "../../common/overlay-trigger/pmivr-overlay-trigger";

import ElementService from "../../../services/element.service";

/**
 * Enable/Disable speech recognition option, On enabled, grammar (numeric/digit etc) can be selected.
 * Will introduce attributes like speechInputGrammar on the passed element so that AGI can respond accordingly
 * @param {{noInputTimeout}} props properties passed to the component
 * @returns {React.Component} render speech input option
 */
const SpeechInput = (props) => {

  // useContext provider VoiceContext provides the value from parent
  const { element } = useContext(VoiceContext);

  // selected grammar from dropdown
  const [selectedGrammar, setSelectedGrammar] = useState("");
  const [speechTimeout, setSpeechTimeout] = useState({
    // in case of user option, set the default value for no speech input as 3000ms for the first time, 
    // and for user input, set the default value as noInputTimeout from props or default value for no speech input timeout
    noInputTimeout:
      [TASK_TYPE.promptUserOption, TASK_TYPE.keyValueUserOption, TASK_TYPE.dynamicUserOption]
        .includes(element.businessObject.taskType)
        ? DEFAULT_USER_OPTION_INPUT_TIMEOUT
        : props.noInputTimeout || APP_CONFIG.DEFAULT_SPEECH_INPUT_TIMEOUT.NO_INPUT,
    maxInputTimeout: APP_CONFIG.DEFAULT_SPEECH_INPUT_TIMEOUT.MAX_INPUT
  });
  // is speech input enabled / diabled
  const [isSpeechInput, setIsSpeechInput] = useState(false);
  // flag to allow user to select the grammar for speech input
  const [allowSelectGrammar, setAllowSelectGrammar] = useState(false);

  // select options for grammar speech
  // ASR_AUTH_GRAMMAR: grammar for authorize payment auth (accept authorize speech input)
  const grammarOptions = [
    { text: "Select Grammar", value: "", attribute: "disabled" },
    { text: "Digit", value: "ASR_DIGIT_GRAMMAR" },
    { text: "Alpha Numeric", value: "ASR_ALPHA_NUM_GRAMMAR" },
    { text: "Currency", value: "ASR_CURRENCY_GRAMMAR" },
    { text: "Authorize", value: "ASR_AUTH_GRAMMAR" }
  ];

  /**
   * on change of input timeout value from props, update the state (noSpeechInputTimeout) with the new value.
   * if we are able to find the value of userOptionNoSpeechInputTimeout / userInputNoSpeechInputTimeout, then 
   * set the same value in state. Otherwise, set the timeout value from props or default value for no speech input timeout
   */
  useEffect(() => {
    const loadNoInputTimeout = () => {
      const taskType = element.businessObject.taskType;
      let inputTimeout = props.noInputTimeout || APP_CONFIG.DEFAULT_SPEECH_INPUT_TIMEOUT.NO_INPUT;
      switch (taskType) {
        case TASK_TYPE.promptUserOption:
        case TASK_TYPE.keyValueUserOption:
        case TASK_TYPE.dynamicUserOption:
          inputTimeout = ElementService.getAttribute(
            element, ATTRIBUTES.USER_OPTION_NO_SPEECH_INPUT_TIMEOUT, inputTimeout
          );

          break;
        case TASK_TYPE.promptUserInput:
          inputTimeout = ElementService.getAttribute(
            element, ATTRIBUTES.USER_INPUT_NO_SPEECH_INPUT_TIMEOUT, inputTimeout
          );
          break;
        default:
          break;
      }
      setSpeechTimeout({ ...speechTimeout, noInputTimeout: inputTimeout });
    }
    loadNoInputTimeout();
  }, [props.noInputTimeout]);

  useEffect(() => {
    const init = () => {
      const taskType = element.businessObject.taskType;
      let speechInputEnabled = false;
      switch (taskType) {
        case TASK_TYPE.promptUserOption:
        case TASK_TYPE.keyValueUserOption:
        case TASK_TYPE.dynamicUserOption:
          speechInputEnabled = ElementService.getAttribute(element, ATTRIBUTES.USER_OPTION_IS_SPEECH_INPUT, false);
          // if user option node, then we know it will be option grammar by default. No need to allow to select grammar
          setAllowSelectGrammar(false);
          setSelectedGrammar(ElementService.getAttribute(element, ATTRIBUTES.USER_OPTION_SPEECH_INPUT_GRAMMAR, ""));
          setIsSpeechInput(speechInputEnabled);
          // reading the value of input timeout from the element. If not there, then default value for no speech input will be used
          const optionInputDefaultTimeout = ElementService.getAttribute(
            element, ATTRIBUTES.USER_OPTION_INPUT_TIMEOUT, APP_CONFIG.DEFAULT_SPEECH_INPUT_TIMEOUT.NO_INPUT
          );
          const userOptionNoInputTimeout = ElementService.getAttribute(
            element, ATTRIBUTES.USER_OPTION_NO_SPEECH_INPUT_TIMEOUT, optionInputDefaultTimeout
          );
          const userOptionMaxSpeechInputTimeout = ElementService.getAttribute(
            element, ATTRIBUTES.USER_OPTION_MAX_SPEECH_INPUT_TIMEOUT, APP_CONFIG.DEFAULT_SPEECH_INPUT_TIMEOUT.MAX_INPUT
          );
          setSpeechTimeout({ ...speechTimeout, noInputTimeout: userOptionNoInputTimeout, maxInputTimeout: userOptionMaxSpeechInputTimeout });
          break;
        case TASK_TYPE.promptUserInput:
          setAllowSelectGrammar(true);
          speechInputEnabled = ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_IS_SPEECH_INPUT, false);
          setSelectedGrammar(ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_SPEECH_INPUT_GRAMMAR, ""));
          setIsSpeechInput(speechInputEnabled);
          // reading the value of input timeout from the element. If not there, then default value for no speech input will be used
          const userInputDefaultTimeout = ElementService.getAttribute(
            element, ATTRIBUTES.USER_INPUT_OPTION_TIMEOUT, APP_CONFIG.DEFAULT_SPEECH_INPUT_TIMEOUT.NO_INPUT
          );
          const userInputNoInputTimeout = ElementService.getAttribute(
            element, ATTRIBUTES.USER_INPUT_NO_SPEECH_INPUT_TIMEOUT, userInputDefaultTimeout
          );
          const userInputMaxSpeechInputTimeout = ElementService.getAttribute(
            element, ATTRIBUTES.USER_INPUT_MAX_SPEECH_INPUT_TIMEOUT, APP_CONFIG.DEFAULT_SPEECH_INPUT_TIMEOUT.MAX_INPUT
          );
          setSpeechTimeout({ ...speechTimeout, noInputTimeout: userInputNoInputTimeout, maxInputTimeout: userInputMaxSpeechInputTimeout });
          break;
        default:
          break;
      }
    }
    init();
    // on change of element state should also get updated
  }, [element]);

  /**
   * updating the modeler with ASR speech input enable flag.
   * If value is false (checkbox is unchecked), then reseting the value of ASR grammar input value as empty
   * @param {Object} e event object of checkbox
   */
  const allowSpeechInput = (e) => {
    const isEnabled = e.target.checked;
    const taskType = element.businessObject.taskType;

    switch (taskType) {
      case TASK_TYPE.promptUserOption:
      case TASK_TYPE.keyValueUserOption:
      case TASK_TYPE.dynamicUserOption:
        ElementService.updateElement(element, ATTRIBUTES.USER_OPTION_IS_SPEECH_INPUT, isEnabled);
        // if user option node, then we know it will be option grammar by default
        const grammar = (isEnabled) ? "ASR_OPTIONS_GRAMMAR" : "";
        ElementService.updateElement(element, ATTRIBUTES.USER_OPTION_SPEECH_INPUT_GRAMMAR, grammar);
        break;
      case TASK_TYPE.promptUserInput:
        // updating the selected element in modeler
        ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_IS_SPEECH_INPUT, isEnabled);
        ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_SPEECH_INPUT_GRAMMAR, "");
        break;
      default:
      // do nothing
    }
    // updating the state
    setIsSpeechInput(isEnabled);
    if (isEnabled) {
      // updating no input timeout value for the element to the value in state, when you enable the speech input.
      // Done when you create the user input and you just update the user input timeout text field in the starting
      // and going down to enable the speech input checkbox, then updating the same value to the 
      // no input timeout for speech input element for speech input.
      updateSpeechTimeout("noInput", speechTimeout.noInputTimeout);

      // Automatically select the option with attribute === "selected"
      const selectedOption = grammarOptions.find(option => option.attribute === "selected");
      if (selectedOption) {
        setSelectedGrammar(selectedOption.value);
      }
    } else {
      setSelectedGrammar(""); // reset grammar when speech input is disabled
    }
  }

  /**
   * updating the modeler and state with ASR grammar input value
   * @param {Object} event event object of select box of ASR Input
   */
  const updateGrammar = (event) => {
    const value = event.target.value;
    setSelectedGrammar(value); // updating the state
    if (element.businessObject.taskType === TASK_TYPE.promptUserInput) {
      ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_SPEECH_INPUT_GRAMMAR, value); // updating the modeler
    }
  }

  /**
   * Updates the modeler with the speech input timeout values for no speech input and max speech input timeout values for the selected element
   * @param {Number} value timeout value to be updated
   * @param {String} type type of timeout value to be updated
   */
  const updateSpeechTimeout = (type, value) => {
    if (element.businessObject.taskType === TASK_TYPE.promptUserInput) {
      if (type === 'noInput') {
        ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_NO_SPEECH_INPUT_TIMEOUT, value);
      } else if (type === 'maxInput') {
        ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_MAX_SPEECH_INPUT_TIMEOUT, value);
      }
    } else if ([TASK_TYPE.promptUserOption, TASK_TYPE.keyValueUserOption, TASK_TYPE.dynamicUserOption].includes(element.businessObject.taskType)) {
      if (type === 'noInput') {
        ElementService.updateElement(element, ATTRIBUTES.USER_OPTION_NO_SPEECH_INPUT_TIMEOUT, value);
      } else if (type === 'maxInput') {
        ElementService.updateElement(element, ATTRIBUTES.USER_OPTION_MAX_SPEECH_INPUT_TIMEOUT, value);
      }
    }
    setSpeechTimeout({ ...speechTimeout, [`${type}Timeout`]: value });
  }

  return (
    <>
      <Accordion className="my-3 pmivr-accordion" flush>
        <Accordion.Item eventKey={ACCORDION.SPEECH_INPUT_EVENT_KEY} className="mt-3 accordion-voice-item">
          <Accordion.Header>
            <span className="pmivr-accordian-tab">
              Speech Input
            </span>
          </Accordion.Header>
          <Accordion.Body className="p-3 pt-0">
            <div className="form-check pmivr-check-radio form-check-inline mb-2 mt-3 check-box-label-align">
              <input className="form-check-input" type="checkbox" id="is-speech-input" checked={isSpeechInput}
                onChange={(e) => { allowSpeechInput(e); }} />
              <label className="form-check-label">
                Enable Speech Input
              </label>
              <PmivrTooltip message={TOOLTIP.INFO.IS_SPEECH_INPUT}>
                <i className={`${TASK_ICONS.DISPLAY_INFO} check-box-info-icon`}></i>
              </PmivrTooltip>
            </div>
            <div className="row mt-3">
              <div className="col-md-6 form-group mb-3">
                <PmivrLabel label="No Input Timeout" tooltip={TOOLTIP.INFO.NO_SPEECH_INPUT_TIMEOUT} />
                <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.NO_SPEECH_INPUT_TIMEOUT}>
                  <input
                    id="nit" name="nit" className="form-control pmivr-input" type="number" placeholder="Enter No Input Timeout (in ms)"
                    value={speechTimeout.noInputTimeout} disabled={!isSpeechInput}
                    onChange={(event) => {
                      updateSpeechTimeout("noInput", event.target.value);
                    }}
                  />
                </PmivrOverlayTrigger>
              </div>
              <div className="col-md-6 form-group mb-3">
                <PmivrLabel label="Max Input Timeout" tooltip={TOOLTIP.INFO.MAX_SPEECH_TIMEOUT} />
                <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.MAX_SPEECH_TIMEOUT}>
                  <input
                    id="maxSpeechTimeout" name="maxSpeechTimeout" className="form-control pmivr-input" type="number"
                    placeholder="Enter Max Speech Timeout (in ms)" value={speechTimeout.maxInputTimeout} disabled={!isSpeechInput}
                    onChange={(event) => {
                      updateSpeechTimeout("maxInput", event.target.value);
                    }}
                  />
                </PmivrOverlayTrigger>
              </div>
            </div>
            <div className={allowSelectGrammar ? "" : "pmivr-hide-display"}>
              <div className="pmivr-label">
                <label className={selectedGrammar ? "pmivr-label pb-2" : "pmivr-hide-display"}>Select Grammar</label>
              </div>
              <select id="selected-grammar" className="pmivr-select mb-3" disabled={!isSpeechInput}
                onChange={(e) => { updateGrammar(e); }} value={selectedGrammar}>
                {grammarOptions.map((option) => {
                  return (
                    <option key={option.value} value={option.value} disabled={option?.attribute === "disabled"}>
                      {option.text}
                    </option>
                  );
                })}
              </select>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

// Types of props passed in the component
SpeechInput.propTypes = {
  noInputTimeout: PropTypes.number
}

export default SpeechInput;