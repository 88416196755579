import React, { useContext, useState, useEffect, useRef } from "react";
import Chips from 'react-chips';
import { useFormik } from "formik";

import { ATTRIBUTES } from "../../../../constants/attributes";
import { MESSAGES, TOOLTIP } from "../../../../constants/messages";
import { SERVICE_TYPES } from "../../../../constants/task-types";
import { APP_CONFIG } from "../../../../config/config";
import { APICallValidationSchema } from "./formik/api-call.schema";

import { VoiceContext } from "../../../../contexts/app-context";

import PmivrOverlayTrigger from "../../../common/overlay-trigger/pmivr-overlay-trigger";
import PmivrSnackBar from "../../../common/dialog/pmivr-snackbar";
import PmivrLabel from "../../../common/label/pmivr-label";

import ElementService from "../../../../services/element.service";
import DiagramService from "../../../../services/diagram.service";

/**
 * Component for configuring API Call service properties.
 */
const ApiCallServiceView = () => {

  const { element } = useContext(VoiceContext);

  const methodOptions = ["GET", "POST", "PUT", "DELETE"];

  // Available response variables as chips in the input field
  const [availableResponseVariables, setAvailableResponseVariables] = useState([]);

  // to use the snackbar methods
  const snackbarRef = useRef();

  /**
   * Formik initialization for handling form state and validation.
   */
  const formik = useFormik({
    /**
     * Initial values for the form fields.
     * @type {Object}
     */
    initialValues: {
      apiUrl: "", apiMethod: "GET", apiQueryParams: "", apiHeaders: "", apiPayload: "", responseVariables: availableResponseVariables,
      authUrl: "", authMethod: "GET", authResponseVariable: "", authPayload: "", authParams: "",
    },
    // Validation schema for form validation.
    validationSchema: APICallValidationSchema,
    /**
     * Submission handler for the form.
     * @param {Object} values - The form values after validation.
     */
    onSubmit: (values) => {
      handleSave(values);
    },
  });

  /**
   * Effect to initialize form fields and element attributes.
   * @param {Object} element - The current element to read attributes from.
   * @param {Function} setAvailableResponseVariables - Setter for available response variables.
   * @param {Object} formik - Formik instance for managing form state.
   * @param {Object} snackbarRef - Reference to the snackbar for displaying messages.
   */
  useEffect(() => {
    try {
      // Set default API method to GET if not defined
      const apiMethod = ElementService.getAttribute(element, ATTRIBUTES.SERVICE.API_METHOD);
      if (!apiMethod) {
        // if not defined, then set it to GET
        ElementService.updateElementAttr(element, ATTRIBUTES.SERVICE.API_METHOD, methodOptions[0]);
        formik.setFieldValue("apiMethod", ElementService.getAttribute(element, ATTRIBUTES.SERVICE.API_METHOD));
      }
      // populate data only when api call is selected
      if (ElementService.getAttribute(element, ATTRIBUTES.SERVICE.TYPE, "") === SERVICE_TYPES.API_CALL) {
        const authJson = ElementService.getAttribute(element, ATTRIBUTES.SERVICE.AUTH, "{}");
        const parsedAuth = JSON.parse(authJson);
        const apiRespVariables = ElementService.getAttribute(element, ATTRIBUTES.SERVICE.RESPONSE_VARIABLES, "{}");
        // Because response variables are comma separated values, to convert them into array
        const apiResponseVariablesArray = apiRespVariables.split(',').map(item => item.trim());
        // Initially set the input field for response variables as well
        setAvailableResponseVariables(apiResponseVariablesArray);
        // Initialize form values
        formik.setFieldValue("responseVariables", apiResponseVariablesArray);
        formik.setValues({
          apiUrl: ElementService.getAttribute(element, ATTRIBUTES.SERVICE.API_URL, ""),
          apiMethod: ElementService.getAttribute(element, ATTRIBUTES.SERVICE.API_METHOD),
          apiQueryParams: ElementService.getAttribute(element, ATTRIBUTES.SERVICE.API_QUERY_PARAMS, ""),
          apiHeaders: ElementService.getAttribute(element, ATTRIBUTES.SERVICE.API_HEADERS, ""),
          apiPayload: (ElementService.getAttribute(element, ATTRIBUTES.SERVICE.API_METHOD) === "POST" ||
            ElementService.getAttribute(element, ATTRIBUTES.SERVICE.API_METHOD) === "PUT") ?
            ElementService.getAttribute(element, ATTRIBUTES.SERVICE.API_PAYLOAD, "") : '',
          authUrl: parsedAuth.url || "",
          authMethod: parsedAuth.method || "GET",
          authResponseVariable: parsedAuth.responseVariables || "",
          authParams: parsedAuth.params || "",
          authPayload: parsedAuth.payload || "",
        })
      }
    } catch (error) {
      if (snackbarRef?.current) {
        snackbarRef.current.open(MESSAGES.ERR.JSON_PARSE_ERROR);
      }
    }
  }, [element]);

  /**
   * Saves form data and updates element attributes.
   * @param {Object} values - The form values to save.
   */
  const handleSave = (values) => {
    try {
      // Map for direct attribute updates
      const attributesToUpdate = {
        [ATTRIBUTES.SERVICE.API_URL]: values.apiUrl,
        [ATTRIBUTES.SERVICE.API_METHOD]: values.apiMethod,
        [ATTRIBUTES.SERVICE.API_QUERY_PARAMS]: values.apiQueryParams,
        [ATTRIBUTES.SERVICE.API_HEADERS]: values.apiHeaders,
        [ATTRIBUTES.SERVICE.API_PAYLOAD]: values.apiPayload,
        // Convert the available response variables to a CSV string
        [ATTRIBUTES.SERVICE.RESPONSE_VARIABLES]: availableResponseVariables.join(','),
        [ATTRIBUTES.SERVICE.AUTH]: JSON.stringify({
          url: values.authUrl,
          method: values.authMethod,
          responseVariables: values.authResponseVariable,
          params: values.authParams,
          payload: values.authPayload
        }),
        // eslint-disable-next-line
        [ATTRIBUTES.SERVICE_IMPLEMENTATION_METHOD]: "${environment.services.handleServiceApiCall}",
        [ATTRIBUTES.SERVICE.TYPE]: SERVICE_TYPES.API_CALL,
      };
      // Update each attribute on the element
      Object.entries(attributesToUpdate).forEach(([key, value]) => {
        ElementService.updateElement(element, key, value);
      });
      // snackbar message : saved successfully
      snackbarRef?.current?.open(MESSAGES.SAVED_SUCCESSFULLY);
      setTimeout(() => { DiagramService.closeRightPanel() }, APP_CONFIG.MESSAGE_TIMEOUT);
    } catch (error) {
      snackbarRef?.current?.open(MESSAGES.ERR.SOMETHING_WENT_WRONG);
    }
  };

  return (
    <>
      <PmivrSnackBar ref={snackbarRef} />
      <div className="m-2 mt-3">
        <div className="form-group mb-4">
          <PmivrLabel label="Enter API URL" tooltip={TOOLTIP.INFO.API_URL} />
          <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.API_URL}>
            <input id="apiUrl" name="apiUrl" className="form-control pmivr-input" placeholder="Enter URL" value={formik.values.apiUrl}
              onChange={formik.handleChange} onBlur={formik.handleBlur}
            />
          </PmivrOverlayTrigger>
          {formik.touched.apiUrl && formik.errors.apiUrl && (
            <div className="text-danger">{formik.errors.apiUrl}</div>
          )}
        </div>
        <div className="form-group props-custom-input mb-3">
          <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.API_METHOD}>
            <select id="apiMethod" name="apiMethod" className="pmivr-select" value={formik.values.apiMethod}
              onChange={formik.handleChange} onBlur={formik.handleBlur}
            >
              {methodOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <label >Select API Method</label>
          </PmivrOverlayTrigger>
          {formik.touched.apiMethod && formik.errors.apiUrl && (
            <div className="text-danger">{formik.errors.apiMethod}</div>
          )}
        </div>

        {/* Add input field for API_QUERY_PARAMS */}
        <PmivrLabel label="Enter API Query Parameters" tooltip={TOOLTIP.INFO.SERVICE_TASK_INPUT_VARIABLE} />
        <div className="form-group mb-3">
          <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.API_QUERY_PARAMS}>
            <textarea id="apiQueryParams" name="apiQueryParams" className="form-control pmivr-input" rows="4"
              value={formik.values.apiQueryParams}
              onChange={formik.handleChange} onBlur={formik.handleBlur}
              placeholder=" "
            />
          </PmivrOverlayTrigger>
        </div>

        {/* Add input field for API_HEADERS */}
        <PmivrLabel label="Enter API Headers" tooltip={TOOLTIP.INFO.SERVICE_TASK_INPUT_VARIABLE} />
        <div className="form-group mb-3">
          <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.API_HEADERS}>
            <textarea id="apiHeaders" name="apiHeaders" className="form-control pmivr-input" rows="4"
              value={formik.values.apiHeaders}
              onChange={formik.handleChange} onBlur={formik.handleBlur}
              placeholder=" "
            />
          </PmivrOverlayTrigger>
        </div>

        {/* Add input field for API_Payload */}
        <PmivrLabel label="Enter API Payload" tooltip={TOOLTIP.INFO.SERVICE_TASK_INPUT_VARIABLE} />
        <div className="form-group mb-3">
          <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.API_PAYLOAD}>
            <textarea id="apiPayload" name="apiPayload" className="form-control pmivr-input" rows="4" value={formik.values.apiPayload}
              onChange={formik.handleChange} onBlur={formik.handleBlur}
              placeholder=" "
              // Field will be disabled if api method is GET
              disabled={formik.values.apiMethod !== "POST" && formik.values.apiMethod !== "PUT"}
            />
          </PmivrOverlayTrigger>
        </div>

        {/* Response Variables section */}
        <div className="response-variables-section">
          <PmivrLabel label="API Response Variables" tooltip={TOOLTIP.INFO.SERVICE_TASK_RESPONSE_VARIABLE} />
          <div className="form-group props-custom-input pt-1">
            <Chips value={availableResponseVariables} onChange={(newChips) => {
              // Update the state when chips are changed (added or removed)
              setAvailableResponseVariables(newChips);
              formik.setFieldValue("responseVariables", newChips);
            }}
              suggestions={formik.values.responseVariables}
            />
            {formik.touched.responseVariables && formik.errors.responseVariables && (
              <div className="text-danger">{formik.errors.responseVariables}</div>
            )}
          </div>
        </div>

        {/* Create Auth section */}
        <div className="auth-section">
          <h6>Auth Configuration</h6>
          <div className="form-group mb-4">
            <PmivrLabel label="Enter Auth URL" tooltip={TOOLTIP.INFO.AUTH_URL} />
            <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.AUTH_URL}>
              <input id="authUrl" name="authUrl" className="form-control pmivr-input" placeholder="Enter URL" value={formik.values.authUrl}
                onChange={formik.handleChange} onBlur={(e) => {
                  formik.handleBlur(e);
                }}
              />
            </PmivrOverlayTrigger>
            {formik.touched.authUrl && formik.errors.authUrl && (
              <div className="text-danger">{formik.errors.authUrl}</div>
            )}
          </div>

          <div className="form-group props-custom-input mb-2">
            <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.API_METHOD}>
              <select id="authMethod" name="authMethod" className="pmivr-select" value={formik.values.authMethod}
                onChange={formik.handleChange} onBlur={formik.handleBlur}>
                {methodOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <label>Select Auth Method</label>
            </PmivrOverlayTrigger>
            {formik.touched.authMethod && formik.errors.authMethod && (
              <div className="text-danger">{formik.errors.authMethod}</div>
            )}
          </div>
          <div className="form-group mb-3">
            <PmivrLabel label="Enter Auth Response Variable" tooltip={TOOLTIP.INFO.AUTH_RESPONSE_VARIABLE} />
            <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.AUTH_RESPONSE_VARIABLE}>
              <input id="authResponseVariable" name="authResponseVariable" className="form-control pmivr-input" placeholder="Enter Variable"
                value={formik.values.authResponseVariable}
                onChange={formik.handleChange} onBlur={(e) => {
                  formik.handleBlur(e);
                }}
              />
            </PmivrOverlayTrigger>
            {formik.touched.authResponseVariable && formik.errors.authResponseVariable && (
              <div className="text-danger">{formik.errors.authResponseVariable}</div>
            )}
          </div>
          <div className="form-group props-custom-input mb-3">
            <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.AUTH_PAYLOAD}>
              <textarea id="authPayload" name="authPayload" className="form-control pmivr-input" rows="4"
                // In case of get method, empty the field
                value={formik.values.authMethod === "POST" ? formik.values.authPayload : ""}
                onChange={formik.handleChange} onBlur={formik.handleBlur}
                placeholder=" "
                // Field will be disabled if auth method is GET
                disabled={formik.values.authMethod !== "POST" && formik.values.authMethod !== "PUT"}
              />
              <label className="pt-1">Enter Auth Payload</label>
            </PmivrOverlayTrigger>
            {formik.touched.authPayload && formik.errors.authPayload && (
              <div className="text-danger">{formik.errors.authPayload}</div>
            )}
          </div>

          <div className="form-group mb-3">
            <PmivrLabel label="Enter Query Params (JSON string)" tooltip={TOOLTIP.INFO.AUTH_PARAMS_JSON_STRING} />
            <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.AUTH_PARAMS_JSON_STRING}>
              <textarea id="authParams" name="authParams" className="form-control pmivr-input" value={formik.values.authParams} rows="4"
                onChange={formik.handleChange} onBlur={formik.handleBlur}
              />
            </PmivrOverlayTrigger>
          </div>
        </div>
        <div className="pt-1 w-100 bg-white position-fixed position-bottom">
          <button type="button" className="pmivr-btn-cancel me-2" onClick={DiagramService.closeRightPanel}>
            Cancel
          </button>
          <button type="button" className="pmivr-btn-app" onClick={formik.handleSubmit}>
            Save
          </button>
        </div>
      </div >
    </>
  );
};

export default ApiCallServiceView;