import store from "../redux/store/store";
import { updateTaskActionParams } from "../redux/actions/client.action";
import { updateShowReUsableDialog } from "../redux/actions/re-usable-control.action";

import { EL_TYPE, ELEMENT_COLORS } from "../constants/element";
import { ATTRIBUTES } from "./../constants/attributes";
import { CSS_CLASSES } from "../constants/css-classes";
import { WIZARD_QUESTION_ID } from "../constants/wizard-questions-id";
import { SERVICE_TYPES, TASK_TYPE } from "../constants/task-types";
import { VOICE_FILE_UPLOAD_TYPE } from "../constants/voice-file";

import DiagramUtil from "../util/diagram.util";
import ElementUtil from "../util/element.util";
import UrlUtil from "../util/url.util";

import { APP_CONFIG, SHAPE } from "../config/config";

import ElementService from "./element.service";
import FlowService from "./flow.service";
import FlowLayoutService from "./flow-layout.service";
import ModelerService from "./modeler.service";

/**
 * Handle the diagram specific to loaded businessCode
 */
class DiagramService {

  // coordinates and info of expaneded element
  static _elementInfo = { x: 0, y: 0 };

  static _operators = {
    basic: [
      { name: "Equals", operator: "===" },
      { name: "Not Equals to", operator: "!==" },
      { name: "Less Than", operator: "<" },
      { name: "Greator Than", operator: ">" },
      { name: "Less Than Equals", operator: "<=" },
      { name: "Greator Than Equals", operator: ">=" },
      // operator is not required as based on this operator need to check if variable selected is defined or not
      { name: "Is defined", operator: "IS_DEFINED" },
      { name: "Not defined", operator: "NOT_DEFINED" },
      // to apply javascript properties/functions on selected variable, like .length, .split(),.includes()
      { name: "JS Expression", operator: "JS_EXPRESSION" },
    ],
    logical: [
      { name: "OR", operator: "||" },
      { name: "AND", operator: "&&" },
    ],
  };

  // info of element for which disable/enable button is clicked
  static _selectedElement = null;
  // element for which properties panel is opened, for highlighting
  static _highlightedElement = null;

  /**
   * Close the right panel tool bar
   */
  static closeRightPanel() {
    const rightPanel = document.getElementById(CSS_CLASSES.V_PILLS_TABCONTENT);
    if (rightPanel && rightPanel.style.display === CSS_CLASSES.BLOCK) {
      // when closing the right panel close the context pad
      DiagramService.closeContextPad();
      // if element is selected then avoid its selection since we want the updated state in right panel
      const element = document.querySelector('.djs-element.selected');
      if (element) {
        element.classList.remove('selected');
        // get the id of current element for which panel is being closed
        const elementId = element.getAttribute('data-element-id');
        if (elementId) {
          // remove the highlight after closing
          ElementUtil.toggleActiveElementHighlight(elementId, false);
        }
      }
    }
    const [diagramWrapper] = document.getElementsByClassName(CSS_CLASSES.DIAGRAM_WRAPPER);
    if (diagramWrapper) {
      diagramWrapper.classList.remove(CSS_CLASSES.DIAGRAM_DISABLE);
    }
    if (rightPanel) {
      rightPanel.style.display = CSS_CLASSES.NONE;
    }
  }

  static getOperators() {
    return DiagramService._operators;
  }

  // popping up the right panel
  static openRightPanel() {
    const rightPanel = document.getElementById(CSS_CLASSES.V_PILLS_TABCONTENT);
    if (rightPanel) {
      rightPanel.style.display = CSS_CLASSES.BLOCK;
      // Close the context pad when the right panel is opened
      DiagramService.closeContextPad();

      // element for which properties panel is opened
      const element = document.querySelector('.djs-element.selected');
      if (element) {
        const elementId = element.getAttribute('data-element-id');
        if (elementId.startsWith("Gateway")) {
          // to disable the diagram page only when right panel is opened in case of condition
          // if user clicks outside it looses the conditions
          const [diagramWrapper] = document.getElementsByClassName(CSS_CLASSES.DIAGRAM_WRAPPER);
          if (diagramWrapper) {
            diagramWrapper.classList.add(CSS_CLASSES.DIAGRAM_DISABLE);
          }
        }
        // first remove the highlight of the previous element
        if (this._highlightedElement) {
          ElementUtil.toggleActiveElementHighlight(this._highlightedElement, false);
        }
        // now update the highlighted element and highlight it
        this._highlightedElement = elementId;
        ElementUtil.toggleActiveElementHighlight(this._highlightedElement, true);
      }
    }
  }

  // popping up the context pad
  static openContextPad() {
    let contextPadElements = document.getElementsByClassName(CSS_CLASSES.DJS_OVERLAY_CONTEXT_PAD); //divsToHide is an array
    if (contextPadElements) {
      for (let item of contextPadElements) {
        item.style.visibility = CSS_CLASSES.VISIBLE; // or
        item.style.display = CSS_CLASSES.BLOCK; // depending on what you're doing
      }
    }
  }

  /**
   * Hiding the context pad on the diagram page
   */
  static closeContextPad() {
    let contextPadElements = document.getElementsByClassName(CSS_CLASSES.DJS_OVERLAY_CONTEXT_PAD); //divsToHide is an array
    if (contextPadElements) {
      for (let item of contextPadElements) {
        item.style.visibility = CSS_CLASSES.HIDDEN; // or
        item.style.display = CSS_CLASSES.NONE; // depending on what you're doing
      }
    }
  }

  /**
   * Getting the flow diagram info
   * @param {{businessCode,status, versionId, flowName, flowTypeId}}} flowInfo 
   * @returns {Object} Object containing client and payment config and other info
   */
  static async getDiagramInfo(flowInfo) {
    const flowDiagram = await FlowService.downloadFlow(flowInfo);

    return { modeler: ModelerService.getModeler(), diagram: flowDiagram.data };
  }

  /**
   * Create the shapes for the diagram
   * @param {*} bpmnFactory
   * @param {*} elementFactory
   * @param {*} variables
   * @param {*} elementType
   */
  static createShape(bpmnFactory, elementFactory, variables, elementType) {
    let businessObject = DiagramService.createBusinessObject(bpmnFactory, elementType);

    if (variables) {
      // expected a return value
      Object.keys(variables).map((key) => {
        businessObject[key] = variables[key];
        // returning the key because map method needs a return statement
        return key;
      });
    }

    const shape = elementFactory.createShape({ type: elementType, businessObject: businessObject });
    return shape;
  }

  /**
   * Create teh business object of the given type
   * @param {*} bpmnFactory
   * @param {*} elementType
   */
  static createBusinessObject(bpmnFactory, elementType) {
    return bpmnFactory.create(elementType);
  }

  /**
   * Create default bpmn object (to create default bpmn object you should use elementFactory.createBpmnElement this method)
   * @param {*} type type of bpmn element you want to create
   */
  static createBpmnElement(type) {
    const modeler = ModelerService.getModeler();
    const elementFactory = modeler.get("elementFactory");
    const startTask = elementFactory.createBpmnElement("shape", type);
    return startTask;
  }

  /**
   * Dispatch true for opening the export re usable dialog modal on diagram page, 
   * since modal can not be directly used in class, so triggering from redux
   * Same way we are opening the dialog for disable the node, same way we need to open in case of re usable
   * @param {Object} element - flow element
   */
  static triggerReUsableDialog(element) {
    // since the confirm dialog box is used in the diagram page, need to trigger the opening of modal from service,
    // can not directly use the react component in the customContextPad class or in diagram service,
    // dispatch true for the boolean value used in the diagram page for showDialog
    store.dispatch(updateShowReUsableDialog({ showReUsableDialog: true }));
    this._selectedElement = element;
    DiagramService.closeContextPad();
  }

  /**
   * Dispatch true for opening the confirm disable/enable task modal on diagram page, 
   * since modal can not be directly used in class, so triggering from redux
   * @param {Object} element - flow element
   * @param {Object} task - type of task: disable or enable 
   */
  static triggerConfirmationDialog(element, task) {
    const elementActionDialogParams = { showDialog: true, action: task };
    // since the confirm dialog box is used in the diagram page, need to trigger the opening of modal from service,
    // can not directly use the react component in the customContextPad class or in diagram service,
    // dispatch true for the boolean value used in the diagram page for showDialog
    store.dispatch(updateTaskActionParams({ elementActionDialogParams }));
    // element of which re usable icon of export is clicked in custom context pad
    this._selectedElement = element;
    DiagramService.closeContextPad();
  }

  /**
   * Export the component as re usable so that we can easily drag and drop ,
   * To avoid re creation of nodes again and again
   */
  static async exportAsReUsableComponent() {
    if (this._selectedElement) {
      const element = this._selectedElement;
      const newElementInfo = await this.generateReUsableElement(element);
      const response = await FlowService.addReUsableElements(newElementInfo);
      if (response) {
        store.dispatch(updateShowReUsableDialog({ showReUsableDialog: false }));
      }
    }
  }

  /**
   * Generate the Re Usable element based on element information like attributes childrens and flow lines
   * Basically it converts whole data into the json object which will be helpful in creating re usable element
   * @param {{id,name,businessObject,attributes}} elementToExport 
   * @returns {{name,id,childrens:[],flows:[]}} json object reqd for exporting re usable element
   */
  static async generateReUsableElement(elementToExport) {
    // create new element info that will be saved in the Db
    const newElementInfo = {
      name: elementToExport?.businessObject?.name || '',
      id: elementToExport?.businessObject?.id,
      elementType: elementToExport?.type,
      type: "custom"
    };
    // create childrens and flows array that will store child elements and flows will store flow lines 
    let children = [];
    let flows = [];

    if (elementToExport?.type === EL_TYPE.SUB_PROCESS) {
      const childrenElements = elementToExport?.children;

      // Process children elements
      childrenElements.forEach((element) => {
        if (element?.type === EL_TYPE.SEQUENCE_FLOW) {
          const sequenceFlow = {
            id: element.id,
            isFixedId: true,
            sourceRef: element.businessObject?.sourceRef?.id || "",
            targetRef: element.businessObject?.targetRef?.id || "",
            // condition expression is basically an attribute of sequence flow in case of condition comes
            conditionExpression: element?.businessObject?.conditionExpression ? [element?.businessObject?.conditionExpression?.body] : []
          };
          flows.push(sequenceFlow);
        } else if (element?.type !== EL_TYPE.LABEL) {
          // Handle other BPMN elements except labels, we do not want any kind of label we do not use it
          children.push({
            id: element.id,
            name: element?.businessObject?.name || '',
            task: element?.type || '',
            isFixedId: element?.type === EL_TYPE.GATEWAY ? "true" : true,
            // get the attributes since we have attributes as a moddle element , we need specif attributes only 
            // so we filter it
            attributes: this.filterElementAttributes(element)
          });
        }
      });

      // Add flows and children to the subprocess info
      newElementInfo.children = children;
      newElementInfo.flows = flows;
    } else {
      newElementInfo.attributes = this.filterElementAttributes(elementToExport);
    }
    return newElementInfo;
  }

  /**
   * Elements whose attributes we need to filter out , since we only need some particular attributs used in re usable componnets
   * Attributes is a moddle object and has fields like $type , some functions , but these are not reqd in json object
   * @param {*} el element whose attributes to filter 
   * @returns {Object} Updated filtered attributes
   */
  static filterElementAttributes(el) {
    const businessObject = el.businessObject;
    if (!businessObject) {
      return {};
    }

    // Filter out attributes with `$` in the key, that are objects, or are `id` and `name`
    return Object.keys(businessObject).reduce((result, key) => {
      const value = businessObject[key];
      if (!key.includes('$') && typeof value !== 'object' && key !== 'id' && key !== 'name') {
        result[key] = value;
      }
      return result;
    }, {});
  }

  /**
   * Disables a task by setting attribute in xml
   */
  static disableTask() {
    if (this._selectedElement) {
      // If the element is subprocess, then add disabled attribute in businessObject and disable its children
      if (this._selectedElement.type === EL_TYPE.SUB_PROCESS) {
        ElementService.updateElementAttr(this._selectedElement, "isDisabledSubprocess", "true");
        const children = this._selectedElement?.children;
        children?.forEach((child) => {
          const taskType = child.businessObject.taskType;
          // according to different task types, isDisabledAttribute will be different
          // eg: for voice - voice:isDisabledVoice
          const isDisabledAttribute = DiagramUtil.generateDisabledAttribute(taskType);
          ElementService.updateElement(child, isDisabledAttribute, "true");
        });
      } else {
        const taskType = this._selectedElement.businessObject.taskType;
        const isDisabledAttribute = DiagramUtil.generateDisabledAttribute(taskType);
        ElementService.updateElementAttr(this._selectedElement, isDisabledAttribute, "true");
      }

      // change the highlight and label colors to grey for disabled
      DiagramUtil.changeElementHighlightColor(this._selectedElement.id, ELEMENT_COLORS.DISABLED_COLOR,
        ELEMENT_COLORS.DISABLED_COLOR);

      const elementActionDialogParams = { showDialog: false, action: '' };
      store.dispatch(updateTaskActionParams({ elementActionDialogParams }));
    }
    this._selectedElement = {};
  }

  /**
   * Enables a task, i.e. set disabled to false
   */
  static enableTask() {
    if (this._selectedElement) {
      // Also disable all the child process if the task is a sub process
      if (this._selectedElement.type === EL_TYPE.SUB_PROCESS) {
        ElementService.updateElement(this._selectedElement, "isDisabledSubprocess", "false");
        const children = this._selectedElement?.children;
        children?.forEach((child) => {
          const taskType = child.businessObject.taskType;
          const isDisabledAttribute = DiagramUtil.generateDisabledAttribute(taskType);
          ElementService.updateElement(child, isDisabledAttribute, "false");
        });
      } else {
        const taskType = this._selectedElement.businessObject.taskType; // sayData
        const isDisabledAttribute = DiagramUtil.generateDisabledAttribute(taskType);
        ElementService.updateElement(this._selectedElement, isDisabledAttribute, "false");
      }
      DiagramUtil.changeElementHighlightColor(this._selectedElement.id, ELEMENT_COLORS.NODE_HEADING_TEXT_COLOR,
        ELEMENT_COLORS.LINE_AT_NODE_TOP_COLOR);

      // Also change the disabled attribute for the parent 
      // (if any process from a disabled subprocess is enabled, then subprocess should be enabled)
      if (this._selectedElement?.parent.type === EL_TYPE.SUB_PROCESS) {
        ElementService.updateElementAttr(this._selectedElement.parent, "disabled", false);
      }

      const elementActionDialogParams = { showDialog: false, action: '' };
      store.dispatch(updateTaskActionParams({ elementActionDialogParams }));
    }
    this._selectedElement = {};
  }

  /**
   * Add new element to it's parent element
   * @param {*} element  sibling element of the newly created element
   * @param {*} newElement new element which you want to add in parent element
   * @returns {*} returns an object of the newly added element
   */
  static addNewElementInParentElement(element, newElement) {
    const modeler = ModelerService.getModeler();
    const elementRegistry = modeler.get("elementRegistry");
    const modeling = modeler.get("modeling");
    const parentId = ElementService.getParentId(element);
    const parentElement = elementRegistry.get(parentId);
    // set coordinates of the element
    const shapeX = element.x + SHAPE.SHAPE_WIDTH + SHAPE.DISTANCE;
    const shapY = element.y + SHAPE.ALIEN_OBJECT_VERTICALLY;
    // add element to it's parent element
    modeling.createShape(newElement, { x: shapeX, y: shapY }, parentElement);
    return elementRegistry.get(newElement.id);
  }

  /**
   * Create a new end element and add it to it's parent element
   * @param {*} element sibling element object
   * @returns {Object} returns an object of the newly added end element
   */
  static addEndEventInParent(element) {
    const endTask = DiagramService.createBpmnElement({
      type: "bpmn:EndEvent",
    });
    const newEndElement = DiagramService.addNewElementInParentElement(element, endTask);
    return newEndElement;
  }

  /**
   * Handle click of expand arrow icon on element
   * Hide the Other elements and flow arrows
   * @param {*} element
   */
  static expandElementHandler(element) {
    // to hide the incoming arrows on subprocess boundary
    DiagramUtil.hideIncomingFlowLines(element);
    // align the labels inside subprocess for all elements
    DiagramUtil.alignAllLabels();

    // on collapse element position is getting changed
    // save the coordinates so that on collapse element can be positioned on the actual position
    DiagramService._elementInfo.x = element.x;
    DiagramService._elementInfo.y = element.y;

    const modeling = ModelerService.getModeler().get("modeling");
    const elementRegistry = ModelerService.getModeler().get("elementRegistry");

    // to convert collections to array need to use ...
    // hide all the elements (document usage cannot be removed as it is done for bpmn element that is created dynamically)
    const els = [...document.getElementsByClassName("djs-element")];
    els.forEach((el) => {
      el.style.display = "none";
    });

    // to convert collections to array need to use ...
    // hide all custom expand buttons (document usage cannot be removed as it is done for bpmn element that is created dynamically)
    const btns = [...document.getElementsByClassName("pmivr-expand-button")];
    btns.forEach((el) => {
      el.style.display = "none";
    });

    // get the id of incoming and outgoing arrows and hide them with CSS.
    // inside subprocess incoming/outgoing arrow from/to sibling will be visible; hide them
    const incomingArrowId = element.incoming[0]?.id;
    const outgoingArrowId = element.outgoing[0]?.id;
    // to convert collections to array need to use ...  (document usage cannot be removed as it is done for bpmn element that is created dynamically)
    const incomingEle = document.querySelector(`[data-element-id="${incomingArrowId}"]`);

    if (incomingEle) {
      [...incomingEle?.children]?.forEach((el) => {
        el.style.display = "none";
      });
    }

    // to convert collections to array need to use ... (document usage cannot be removed as it is done for bpmn element that is created dynamically)
    const outgoingELe = document.querySelector(`[data-element-id="${outgoingArrowId}"]`);

    if (outgoingELe) {
      [...outgoingELe?.children]?.forEach((el) => {
        el.style.display = "none";
      });
    }

    // expand the selected element
    modeling.toggleCollapse(element);

    // get the Object of element
    // expanded element to position in visible area; centre  (document usage cannot be removed as it is done for bpmn element that is created dynamically)
    const ele = document.querySelectorAll(`[data-element-id^="${element.id}"]`)[0];
    const bbox = elementRegistry.get(ele);
    // find the Middle of the element
    const elementMid = {
      x: bbox.x + bbox.width / 2,
      y: bbox.y + bbox.height / 2,
    };
    // hiding the three dots of subprocesses on expanding the subprocess
    let contextPadElements = document.getElementsByClassName(CSS_CLASSES.SUBPROCESS_CONTEXT_PAD); //divsToHide is an array
    if (contextPadElements) {
      for (let item of contextPadElements) {
        item.style.visibility = CSS_CLASSES.HIDDEN; // or
        item.style.display = CSS_CLASSES.NONE; // depending on what you're doing
      }
    }
    // scroll element into center with the viewbox of canvas
    ModelerService.getModeler().get("canvas").resized();
    ModelerService.getModeler().get("canvas").zoom("fit-viewport", "auto");
    const currentViewbox = ModelerService.getModeler().get("canvas").viewbox();
    ModelerService.getModeler()
      .get("canvas")
      .viewbox({
        x: elementMid.x - currentViewbox.width / 2,
        y: elementMid.y - currentViewbox.height / 2,
        width: currentViewbox.width,
        height: currentViewbox.height,
      });

    // shwo the collapse button for selected element (document usage cannot be removed as it is done for bpmn element that is created dynamically)
    document.getElementById("clsp" + element.id).style.display = "block";
    // after expanding of the subprocess, highlights the colors for changed nodes
    // added timeout so that all nodes appear on the screen
    setTimeout(() => {
      const changeHistory = store.getState().client.changeHistory;
      changeHistory.forEach((changedElement) => {
        if (changedElement?.changeHistory) {
          DiagramUtil.highlightElement(changedElement.changeHistory.id);
        }
        // for console warnings
        return changedElement;
      });
    }, 500);
  }

  /**
 * Handler click of collapse icon on sub process
 */
  static async collapseElementHandler() {
    const url = new URL(window.location.href);
    // Create a URLSearchParams object with the query string part of the URL
    const queryParams = UrlUtil.getQueryParams(url);
    // check if auto layout is present in query or not, and if present then check if it is true or false
    const autoLayout = queryParams?.has('autoLayout') ? queryParams.get('autoLayout') === "true" : false;
    ModelerService.getModeler().get("canvas").resized();
    const modeling = ModelerService.getModeler().get("modeling");
    const elementRegistry = ModelerService.getModeler().get("elementRegistry");
    const element = elementRegistry.getAll().find((el) => el.type === EL_TYPE.SUB_PROCESS && !el.collapsed);
    if (element) {
      modeling.toggleCollapse(element);
      // move the element to previous position as expanding is changing its actual position
      DiagramService.moveElement(element, DiagramService._elementInfo);
      // apply custom layout settings only when auto layout is not present
      if (!autoLayout) {
        // re connect the element connections
        DiagramService.reConnectElement(element);
      }
      // render the diagram again so that hideen element can render on again
      // that got hidden during expand click
      const diagramXML = await DiagramService.getCurrentDiagramXml();
      DiagramService.renderDiagramCanvas(diagramXML);

      // Align the labels for all elements
      DiagramUtil.alignAllLabels();
    }
    // after collapsing of the subprocess, highlights the colors for changed nodes
    // added timeout so that all nodes appear on the screen
    setTimeout(() => {
      const changeHistory = store.getState().client.changeHistory;
      changeHistory.forEach((changedElement) => {
        if (changedElement?.changeHistory) {
          DiagramUtil.highlightElement(changedElement.changeHistory.id);
        }
        // for console warnings
        return changedElement;
      });
    }, 500);
  }

  /**
   * Gets the Updated Diagram from the modeler
   * @returns diagram xml
   */
  static async getCurrentDiagramXml() {
    const diagramResponse = await ModelerService.getModeler().saveXML({ format: true });
    return diagramResponse.xml;
  }

  /**
   * Renders the digram xml onto the screen
   * @param {*} diagramXML
   */
  static async renderDiagramCanvas(diagramXML, autoLayout = false) {
    if (autoLayout) {
      // generate xml through auto layout functions, to increase spacing and visibility of arrows in bpmn xml
      diagramXML = await FlowLayoutService.autoLayout(diagramXML);
    }
    await ModelerService.getModeler().importXML(diagramXML);
    ModelerService.getModeler().get("canvas").zoom("fit-viewport", "auto");
    // hide default left panel (document usage cannot be removed as it is done for bpmn element that is created dynamically)
    document.getElementsByClassName("djs-palette")[0].style.display = "none";
    DiagramService.updateSubprocessOverlay();
  }

  /**
   * Updating the diagram subprocess overlay and adding inside and outside arrows and it's function on it.
   */
  static updateSubprocessOverlay() {
    const elementRegistry = ModelerService.getModeler().get("elementRegistry");
    const modeling = ModelerService.getModeler().get("modeling");
    const overlays = ModelerService.getModeler().get("overlays");

    elementRegistry.getAll().forEach((element) => {
      if (element.type === "bpmn:SubProcess") {
        if (!element.collapsed) {
          modeling.toggleCollapse(element);
        }
        // element is being generated at runtime, classname won't work here
        const elExpandCollapseArrows = `
              <div style="position:absolute; right:0px;">
                <button class="pmivr-expand-button" id="exp${element.id}"><i class="bi bi-arrows-angle-expand"></i></button>
                <button style="display:none" class="pmivr-expand-close" id="clsp${element.id}"><i class="bi bi-arrows-angle-contract"></i></button>
              <div>
            `;
        let html = DiagramService.createElement(elExpandCollapseArrows);
        overlays.add(element, { position: { left: 0, top: 3 }, html });

        // bind click event to expand the elemnt that will hide all other elements (document usage cannot be removed as it is done for bpmn element that is created dynamically)
        document.getElementById("exp" + element.id).addEventListener("click", () => DiagramService.expandElementHandler(element));

        // apply click to on collapse blue icons on every element (document usage cannot be removed as it is done for bpmn element that is created dynamically)
        document.getElementById("clsp" + element.id).addEventListener("click", () => DiagramService.collapseElementHandler());
      }
    });
  }

  /**
   * Get service expressions warning list like it may have console.log
   * @returns {Array<{elementName,subProcessName}>} serviceExpressionWarningInfo
   */
  static getServiceExpressionWarnings() {
    let serviceExpressionWarningInfo = [];
    const elementRegistry = ModelerService.getModeler().get("elementRegistry");
    elementRegistry.getAll().forEach(async (element) => {

      // first get the voiceTaskType and then we get the missing file paths accordingly
      const taskType = ElementService.getAttribute(element, ATTRIBUTES.VOICE_FILE_TASK_TYPE);
      if (taskType === TASK_TYPE.service) {
        const serviceType = ElementService.getAttribute(element, ATTRIBUTES.SERVICE.TYPE);
        if (serviceType === SERVICE_TYPES.EXPRESSION) {
          const expression = ElementService.getAttribute(element, ATTRIBUTES.SERVICE.EXPRESSION);
          if (expression?.includes("console.log")) {
            // get the element name
            const elementName = ElementService.getElementName(element);
            // get the sub process name
            const subProcessName = ElementService.getParentName(element);
            serviceExpressionWarningInfo.push({ elementName, subProcessName });
          }
        }
      }
    });
    // retuns list of missing voice file paths as msg
    return serviceExpressionWarningInfo;
  }

  /**
   * Get the missing voice files to show warning to user before saving the flow that files are missing
   * @returns {Array<{elementName,language,subProcessName}>} missingVoiceFilesList 
   */
  static getMissingVoiceFiles() {
    let missingVoiceFiles = [];
    const elementRegistry = ModelerService.getModeler().get("elementRegistry");
    elementRegistry.getAll().forEach(async (element) => {

      // first get the voiceTaskType and then we get the missing file paths accordingly
      const voiceTaskType = ElementService.getAttribute(element, ATTRIBUTES.VOICE_FILE_TASK_TYPE);
      if (voiceTaskType) {
        const voiceFileInfo = this._getMissingFileInfo({ voiceTaskType, element });
        missingVoiceFiles = [...missingVoiceFiles, ...voiceFileInfo];
      }
    });
    // retuns list of missing voice file paths as msg
    return missingVoiceFiles;
  }

  /**
   * Get Missing voice file info
   * Like for user input , user option and play voice file components all have voice file info
   * @param {{voiceTaskType,element}} data info like element and taskType
   * @returns {Array<{elementName,language,subProcessName}>} allVoiceFilesInfo Array of info of missing voice files
   */
  static _getMissingFileInfo(data) {
    const { voiceTaskType, element } = data;
    // list where we store the voiceFiles messages
    let allVoiceFilesInfo = [];
    let voiceFileInfo;
    // get the element name
    const elementName = ElementService.getElementName(element);
    const subProcessName = ElementService.getParentName(element);
    if ([TASK_TYPE.playVoiceFile, TASK_TYPE.promptUserInput, TASK_TYPE.promptUserOption].includes(voiceTaskType)) {
      // get the original voiceFileInfo object from xml
      voiceFileInfo = ElementService.getAttribute(element, ATTRIBUTES.VOICE_FILE_INFO);
      // if there exists voiceFileInfo then check for missing path else if voiceFileInfo is missing then 
      // also show missing info
      if (voiceFileInfo) {
        voiceFileInfo = JSON.parse(voiceFileInfo);
        Object.keys(voiceFileInfo).forEach((key) => {
          let fileInfo = {};
          // if there is prompt list and if not simply file info
          if (Array.isArray(voiceFileInfo[key])) {
            const promptsList = voiceFileInfo[key];
            // get the missing prompt info to display as alert
            const missingFileInfo = this._getMissingPromptInfo(element, promptsList, key);
            if (Object.keys(missingFileInfo).length) {
              allVoiceFilesInfo.push(missingFileInfo);
            }
          } else {
            if (!voiceFileInfo[key]?.filePath) {
              fileInfo = { elementName, subProcessName, language: key };
              allVoiceFilesInfo.push(fileInfo);
            }
          }
        });
      } else {
        // in case we do not have voiceFileInfo attribute then we show for both the languages
        const supportedLanguages = store.getState().client.languagesConfigured;
        allVoiceFilesInfo.push({ elementName, subProcessName, language: `${supportedLanguages.join(",")}` });
      }
      // check for invalid voice file info as well
      if (TASK_TYPE.promptUserInput === voiceTaskType) {
        // check for invalid voice file info, confirm voice file info
        [ATTRIBUTES.USER_INPUT_OPTION_INVALID_OPTION_FILE, ATTRIBUTES.CONFIRM_INPUT_VOICE_FILE].map((attribute) => {
          const missingInvalidVoiceFile = this.getMissingVoiceFilesInformation(voiceTaskType, element, attribute);
          if (Object.keys(missingInvalidVoiceFile).length) {
            allVoiceFilesInfo.push(missingInvalidVoiceFile);
          }
        });
      }
    } else if (voiceTaskType === TASK_TYPE.keyValueUserOption) {
      voiceFileInfo = ElementService.getAttribute(element, ATTRIBUTES.KEY_VALUE_MAP);
      voiceFileInfo = JSON.parse(voiceFileInfo);
      // if in key value user option check missing voice file path and also for missing voice file info
      if (voiceFileInfo) {
        Object.keys(voiceFileInfo).forEach((key) => {
          // options list like 1: press-1-file ,2: press-2-file
          const optionsList = voiceFileInfo[key];
          if (optionsList?.length) {
            optionsList.forEach((option) => {
              let fileInfo = {};
              if (!option?.promptsList && !option?.filePath) {
                fileInfo = { elementName, subProcessName, language: key };
                allVoiceFilesInfo.push(fileInfo);
              } else if (option?.promptsList?.length) {
                // get the missing prompt info to display as alert
                const missingFileInfo = this._getMissingPromptInfo(element, option?.promptsList, key);
                // if missing voice file info is there the push in the array of missing files
                if (Object.keys(missingFileInfo).length) {
                  allVoiceFilesInfo.push(missingFileInfo);
                }
              }
            });
          }
        });
      } else {
        // in case we do not have voiceFileInfo attribute then we show for both the languages
        const supportedLanguages = store.getState().client.languagesConfigured;
        allVoiceFilesInfo.push({ elementName, subProcessName, language: `${supportedLanguages.join(",")}` });
      }
    }
    return allVoiceFilesInfo;
  }

  /**
   * Get the missing prompt info like if some file path is missing then we send relevant info to display as alert
   * @param {Object} element
   * @param {Array<{filePath,voiceFileType,isUploadedOnGit}>} promptsList
   * @param {string} key language of which file is missing
   * @returns {{elementName,language,subProcessName}} fileInfo
   */
  static _getMissingPromptInfo = (element, promptsList, key) => {
    let fileInfo = {};
    const elementName = ElementService.getElementName(element);
    const subProcessName = ElementService.getParentName(element);
    for (let i = 0; i < promptsList.length; i++) {
      const prompt = promptsList[i];
      if (prompt?.voiceFileType !== VOICE_FILE_UPLOAD_TYPE.VARIABLE && !prompt?.filePath) {
        fileInfo = { elementName, subProcessName, language: key };
        break;
      }
    }
    return fileInfo;
  }

  /**
   * Get the missing invalid voice file information in promptUserInput
   * @param {string} voiceTaskType taskType like promptUserInput , promptUserOption
   * @param {Object} element
   * @param {string} attribute attribute of element for which missing voice files need to be checked 
   * @returns {{elementName,language,subProcessName}} fileInfo information for missing voice file
   */
  static getMissingVoiceFilesInformation = (voiceTaskType, element, attribute) => {
    // store info of missing invalid voice file
    const fileInfo = {};
    // gett he element name
    const elementName = ElementService.getElementName(element);
    const subProcessName = ElementService.getParentName(element);
    // if readback is off, then no need to check missing files for confirm input
    if (attribute === ATTRIBUTES.CONFIRM_INPUT_VOICE_FILE
      && !ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_OPTION_READBACK)) {
      return fileInfo;
    }
    if (TASK_TYPE.promptUserInput === voiceTaskType) {
      let voiceFileInfo = ElementService.getAttribute(element, attribute);
      voiceFileInfo = voiceFileInfo ? JSON.parse(voiceFileInfo) : {};
      Object.keys(voiceFileInfo).forEach((key) => {
        if (!voiceFileInfo[key]?.filePath) {
          fileInfo["elementName"] = elementName;
          fileInfo["language"] = key;
          fileInfo["subProcessName"] = subProcessName;
        }
      });
    }
    return fileInfo;
  }
  /**
   * Creating the element
   * @param {*} html
   */
  static createElement(html) {
    const template = document.createElement("template"); // bpmn element creation
    html = html.trim(); // Never return a text node of whitespace as the result
    template.innerHTML = html;
    return template.content.firstChild;
  }

  /**
   * Get the same level elements
   * @param {Object} element Element to get the level
   */
  static getSiblingElements(element) {
    const elements = ElementService.getAllElements();
    // add task in this that has no parent .. otherwise all task from even sub process will be added
    const validElementTypes = [EL_TYPE.SUB_PROCESS, EL_TYPE.TASK, EL_TYPE.USER_TASK, EL_TYPE.SERVICE_TASK, EL_TYPE.INTERMEDIATE_THROW_EVENT_TASK, EL_TYPE.END_EVENT, EL_TYPE.GATEWAY];
    // element.parent.parent will be undefined if it is top level element
    const topElements = elements.filter((elementInfo) => validElementTypes.includes(elementInfo.type) && element.parent.id === elementInfo?.parent?.id);
    return topElements;
  }

  /**
   * Get Element by id
   * @param {string} elementId elementId
   * @returns {Object} element having id passed as elementId
   */
  static getElementById(elementId) {
    const elements = ElementService.getAllElements();
    return elements.find((element) => element.id === elementId);
  }

  /**
   * Get Connection by id
   * @param {string} connectionId ConnectionId
   * @returns {Object|null} object containing info of connection if found else null
   */
  static getConnectionById(connectionId) {
    const elements = ElementService.getAllElements();
    // Iterate through each object in the array
    for (const element of elements) {
      if (element?.children?.length) {
        // get the connection from child
        const connection = DiagramService.getConnectionInChildren(element.children, connectionId);
        // If found, return the connection
        if (connection) {
          return connection;
        }
      }
    }
    // If not found, return null
    return null;
  }

  /**
   * Get Connection inside Children by connection id (connection can be in sub process)
   * @param {string} elementChildren child element inside sub process
   * @param {string} connectionId Id of the Connection 
   * @returns {Object|null} object containing info of children connection if found else null
   */
  static getConnectionInChildren(elementChildren, connectionId) {
    // find connection from connectionId within children of elements
    for (const connection of elementChildren) {
      // Check if element is of type "bpmn:SequenceFlow" and has id equal to given connection Id
      if (connection.type === EL_TYPE.SEQUENCE_FLOW && connection.id === connectionId) {
        return connection;
      }
    }
    // If not found, return null
    return null;
  }

  /**
   * Connect two elements
   * @param {Object} source Element
   * @param {Object} target Element
   */
  static connectElement(source, target) {
    const modeling = ModelerService.getModeler().get("modeling");
    return modeling.connect(source, target);
  }

  static deleteConnection(connection) {
    const modeling = ModelerService.getModeler().get("modeling");
    modeling.removeConnection(connection);
  }

  /**
   * Move the elements to mentioned cordinates
   * @param {Object} el Element/Shape
   * @param {{x,y}} coords X and Y coordinates
   */
  static moveElement(el, coords) {
    const modeling = ModelerService.getModeler().get("modeling");
    // get the delta from current and previous coordinates
    const delta = {
      x: coords.x - el.x,
      y: coords.y - el.y,
    };

    // move the shape
    modeling.moveShape(el, delta);
  }

  static reConnectElement(el) {
    const sourceConnection = el.incoming[0];
    const source = sourceConnection?.source;
    // for starting node there can be no source
    if (source) {
      // updating just the connection points for the element collapsed, instead of reconnecting entire bpmn elements
      // reconnecting all the elements again would take much time, so updating the connection of just collapsed element
      FlowLayoutService.updateConnectionPoints(sourceConnection);
    }
  }

  // format the optionAndValue attribute in key Value user option task
  static getFormattedOptionAndVal(element) {
    // format option and val
    // return list of voice file info
    //    [ { "type" : "" , filePath: "" } , ..... ]
    let tempOptionAndVal = [];
    let curOptionAndVal = ElementService.getAttribute(element, ATTRIBUTES.KEY_VALUE_MAP) ? JSON.parse(ElementService.getAttribute(element, ATTRIBUTES.KEY_VALUE_MAP)) : {};
    for (let item of Object.keys(curOptionAndVal)) {
      tempOptionAndVal.push(curOptionAndVal[item]);
    }
    return tempOptionAndVal;
  }

  /**
   * Highlight the element only if element exists in change history
   * @param {string} elementId Id of element 
   */
  static highlightChangedEl(elementId) {
    DiagramUtil.highlightChangedEl(elementId);
  }

  /**
   * Highlighing the element by changing the color of border
   * @param {string} elementId Element id 
   */
  static highlightElement(elementId) {
    DiagramUtil.highlightElement(elementId);
  }

  /**
   * Get all the supported language from language selection element confgiured on wizard
   */
  static async getSupportedLanguages() {
    const flowInfo = store.getState().client.selectedFlowTypeInfo;
    const wizardConfig = flowInfo?.wizardConfig;
    const language = wizardConfig.find((config) => { return config.questionId === WIZARD_QUESTION_ID.SUPPORTED_LANGUAGES; });
    if (!language) {
      // if no language is selected on wizard, then set the default language for it (like for custom flow).
      return [APP_CONFIG.DEFAULT_LANGUAGE_SUPPORTED];
    }
    return language.value;
  }

  /**
   * Places collapse icon on the sub process rectangle with right functionalities
   * Adds expand and collapse icons in dom, and assigns click handler functions to them
   * because collapse, expand icons are custom, and upon undo after delete it does not places the custom icon again
   * @param {Object} element - sub-process element 
   */
  static addExpandCollapseArrows = (element) => {
    // getting the overlays of bpmn modeler
    const overlays = ModelerService.getModeler().get("overlays");
    const elExpandCollapseArrows = `
                <div style="position:absolute; right:0px;">
                  <button class="pmivr-expand-button" id="exp${element.id}"><i class="bi bi-arrows-angle-expand"></i></button>
                  <button style="display:none" class="pmivr-expand-close" id="clsp${element.id}"><i class="bi bi-arrows-angle-contract"></i></button>
                <div>
              `;
    const html = DiagramService.createElement(elExpandCollapseArrows);
    overlays.add(element, { position: { left: 0, top: 3 }, html });
    // bind click event to expand the element that will hide all other elements
    document.getElementById("exp" + element.id).addEventListener("click", () => DiagramService.expandElementHandler(element));
    // apply click to on collapse blue icons on every element
    document.getElementById("clsp" + element.id).addEventListener("click", () => DiagramService.collapseElementHandler());
  }

  /**
   * Adds the id's of children inside the subprocess into an array
   * @param {Object} element 
   * @param {Array} subprocessChildren 
   */
  static collectSubprocessElements(element, subprocessChildren) {
    // Add the subprocess itself to the children array
    subprocessChildren.push(element?.id);

    // Add all child elements of the subprocess to the children array
    element?.children?.forEach(child => {
      subprocessChildren.push(child.id);
    });
  }
}

export default DiagramService;
