class StringUtil {

    /**
     * Convert the given value to boolean value
     * @param {string} value value to be converted
     * @returns {boolean} (true / false)
     */
    static toBoolean(value) {
        return (String(value) === 'true');
    }

    /**
     * Generates a random password with a length between 8 and 12 characters.
     * The password includes at least one lowercase letter, one uppercase letter,
     * one digit, and one special character.
     * @static
     * @returns {string} The generated password.
     */
    static generateRandomPassword = () => {
        // Randomly choose a length between 8 and 12
        const length = Math.floor(Math.random() * 5) + 8; // Set password length between 8 to 12 
        // Define character sets for password generation
        const lowercaseCharset = "abcdefghijklmnopqrstuvwxyz"; // Lowercase letters
        const uppercaseCharset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"; // Uppercase letters
        const numberCharset = "0123456789"; // Numeric digits
        const specialCharset = "!@#$%^&*()_+[]{}|;:',.<>?/`~-="; // Special characters

        // Initialize an array to hold the password characters
        const passwordArray = [
            // Ensure at least one character from each required set
            lowercaseCharset[Math.floor(Math.random() * lowercaseCharset.length)], // Random lowercase character
            uppercaseCharset[Math.floor(Math.random() * uppercaseCharset.length)], // Random uppercase character
            numberCharset[Math.floor(Math.random() * numberCharset.length)], // Random number character
            specialCharset[Math.floor(Math.random() * specialCharset.length)], // Random special character character
        ];

        // Fill the remaining length of the password with random characters from all sets
        const allCharset = lowercaseCharset + uppercaseCharset + numberCharset + specialCharset; // Combined character set
        for (let i = passwordArray.length; i < length; i++) {
            // Randomly select characters from the combined set
            const randomIndex = Math.floor(Math.random() * allCharset.length);
            passwordArray.push(allCharset[randomIndex]); // Add the random character to the password array
        }

        // Shuffle the password array to randomize the order of characters
        const password = passwordArray.sort(() => Math.random() - 0.5).join(''); // Convert array to string and return
        // Return the generated password
        return password;
    };

    /**
     * Check if string is valid json or not
     * @param {string} value
     * @returns {boolean} valid json or not
     */
    static isValidJson(value) {
        try {
            JSON.parse(value);
            return true;
        } catch (error) {
            return false;
        }
    }
}

export default StringUtil;