import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";

import { PAGINATION_COUNT } from "../../config/config";

import { MESSAGES, TOOLTIP } from "../../constants/messages";
import { APP_PAGES } from "../../constants/app-pages";

import PmivrSnackbar from "../../components/common/dialog/pmivr-snackbar";
import PmivrLabel from "../../components/common/label/pmivr-label";
import { PmivrDialog } from "../../components/common/dialog/pmivr-dialog";
import PmivrDateTimeRangePicker from "../../components/common/date-time-range/pmivr-date-time-range";
import LogInfo from "./components/log-info";
import CallLogsList from "./components/call-logs-list";
import LogsFilter from "./model/call-logs-filter.model";

import AppUtil from "../../util/app.util";

import AuditService from "../../services/audit.service";
import ConfigService from "../../services/config.service";

/**
 * Lists all the AGI call logs from db
 * @returns {React.Component} Html element to render
 */
const Logs = () => {
    // using the open method from the snackbar component
    const snackbarRef = useRef();
    // Ref of the date time filter component, used to reset the date time
    const dateTimeRef = useRef();
    // grouped call logs
    const [callLogGroup, setCallLogGroup] = useState([]);
    // call logs summary, total number of calls made, and total number of successful payments
    const [callLogSummary, setCallLogSummary] = useState({ totalCalls: 0, totalSuccessfulPayments: 0, totalFailedPayments: 0 });
    // deployment environments configured
    const [deploymentEnvironment, setDeploymentEnvironment] = useState({ environments: [] });
    /**
     * Properties for show log details dialog:
     * showLogInfoDialog: show or hide log details dialog
     * currentLog: log object which is passed to the details dialog for display
     */
    const [logDetailsDialogProps, setLogDetailsDialogProps] = useState({ showLogInfoDialog: false, currentLog: {} });
    // filter object for filtering the call logs
    const [filterCallLogs, setFilterCallLogs] = useState(
        { text: '', stepName: '', dnid: '', callerIdNum: '', sessionId: '', environment: '', startDate: '', endDate: '', businessCode: '' }
    );
    // props for the pagination of the call logs list
    const [pagination, setPagination] = useState({
        totalPages: 0,    // total pages in pagination of call logs
        currentPage: 0, // current page displayed in pagination of call logs
        totalResults: 0,  // total number of call logs
        count: PAGINATION_COUNT  // count for number of call logs to be displayed at a time on screen
    });
    // State to filter call logs based on session ID for detailed logs.
    const [detailedLogFilter, setDetailedLogFilter] = useState({ sessionId: "" });
    // State to hold the list of call logs to display for detailed logs.
    const [callLogs, setCallLogs] = useState([]);
    // State to manage the visibility of the filter dialog box.
    const [uiState, setUiState] = useState({ showFilterDialog: false });
    // State to handle pagination for detailed logs, including current page and total results.
    const [paginationCallLogs, setPaginationCallLogs] = useState({ currentPage: 0, totalResults: 0, totalPages: 0, count: PAGINATION_COUNT });


    useEffect(() => {
        // loading initial logs list
        const loadInitialCallLogs = async () => {
            await loadCallLogsGroup();
            await loadEnvironments();
        };
        loadInitialCallLogs();
    }, []);

    // loading the deployment environments configured
    const loadEnvironments = async () => {
        const configuredEnv = await ConfigService.getDeploymentEnvironments();
        if (configuredEnv?.length) {
            let options = [{ text: 'Select Environment', value: '', disabled: true }];
            configuredEnv.map((env) => {
                if (env?.key) {
                    options.push({ text: env?.name, value: env?.key, disabled: false });
                }
            });
            setDeploymentEnvironment({ ...deploymentEnvironment, environments: options });
        }
    }

    /**
     * Updating the pagination props in the state
     * @param {Number} dataCount total number of  call logs
     * @param {Number} pageNo page number of the call logs list
     */
    const setPaginationProps = (dataCount = 0, pageNo = 1) => {
        setPagination((prevPaginationObj) => {
            const newPaginationObj = { ...prevPaginationObj };
            newPaginationObj.totalPages = Math.ceil(dataCount / newPaginationObj.count);
            newPaginationObj.currentPage = pageNo;
            newPaginationObj.totalResults = dataCount;
            return newPaginationObj;
        });
    }

    /**
     * Getting the grouped call logs from the DB as per count, page no, etc
     * @param {Integer} pageNo page of call logs list to be displayed, which will be always incremented by default when this function is called
     * pageNo is incremented every time by 1, because we need to load more logs when this function is called, 
     * in case of any filter, we will reset otherwise just increment pageNo by 1
     * @param {string} action whether to reset or next, if the action is next, just append new 10 records to list, otherwise reset
     * @param {Object} filters this is the updated filters, if this function needs latest filters to fetch call logs immediately
     */
    const loadCallLogsGroup = async (pageNo = pagination.currentPage + 1, action = 'next', filters = null) => {
        try {
            const filter = filters || new LogsFilter({ ...filterCallLogs, count: pagination.count, pageNo })
            const response = await AuditService.getIvrCallLogs(filter);

            setCallLogGroup((prevCallLogs) => {
                // move to page 1, and reset pagination if any of the is added
                if (pageNo === 1 || action === 'reset') {
                    return response?.data.logs || [];
                }
                return [...prevCallLogs, ...(response?.data.logs || [])];
            });

            setCallLogSummary({
                totalCalls: response?.data?.totalCalls,
                totalSuccessfulPayments: response?.data?.totalSuccessfulPayments,
                totalFailedPayments: response?.data?.totalFailedPayments
            });

            setPaginationProps(response?.dataCount, pageNo);
            // Close advance search dialog
            setUiState({ showFilterDialog: false });
        } catch (err) {
            // opening the snackbar
            if (snackbarRef?.current) {
                snackbarRef.current.open(MESSAGES.SOMETHING_WENT_WRONG);
            }
        }
    }

    /**
     * Loads call logs with pagination and filtering options.
     * @param {Object} params - The parameters for loading session logs.
     * @param {number} [params.pageNo=paginationCallLogs.currentPage + 1] - The page number to load. Defaults to the next page.
     * @param {string} [params.action='next'] - The pagination action ('next', 'previous', etc.). Defaults to 'next'.
     * @param {Object|null} [params.filters=null] - The filters to apply when fetching logs. Defaults to no filters.
     * @param {string} [params.sessionId=''] - The session ID for which logs should be loaded. Defaults to an empty string.
     * @returns {Promise<void>} - A promise that resolves when the logs are loaded.
     */
    const loadCallLogs = async ({ pageNo = paginationCallLogs.currentPage + 1, action = 'next', filters = null, sessionId = '' }) => {
        try {
            sessionId && setDetailedLogFilter({ sessionId: sessionId });
            // Construct the filter object, using provided filters or defaults
            const filter = filters || new LogsFilter({ ...filterCallLogs, sessionId: detailedLogFilter.sessionId || sessionId, count: paginationCallLogs.count, pageNo });
            // Fetch session logs using the AuditService
            const response = await AuditService.getIvrSessionLogs(filter);
            setCallLogs((prevCallLogs) => {
                if (pageNo === 1 || action === 'reset') {
                    return response?.data.logs || [];
                }
                return [...prevCallLogs, ...(response?.data.logs || [])];
            });
            // Update pagination information based on the response
            const dataCount = response?.dataCount;
            setPaginationCallLogs((prevPaginationObj) => {
                const newPaginationObj = { ...prevPaginationObj };
                newPaginationObj.totalPages = Math.ceil(dataCount / newPaginationObj.count);
                newPaginationObj.currentPage = pageNo;
                newPaginationObj.totalResults = dataCount;
                return newPaginationObj;
            });
            // Close advance search dialog
            setUiState({ showFilterDialog: false });
        }
        catch (err) {
            if (snackbarRef?.current) {
                snackbarRef.current.open(MESSAGES.SOMETHING_WENT_WRONG);
            }
        }
    }

    /**
     * Resetting the list of call logs from state. 
     * If not found, then reload call logs from DB
     */
    const resetData = async () => {
        // also reset the date-time filter
        if (dateTimeRef?.current) {
            dateTimeRef.current.resetDateTime();
        }
        // Clear the cached data and make a fresh API call
        try {
            const response = await AuditService.getIvrCallLogs({
                searchText: '',
                stepName: '',
                dnid: '',
                callerIdNum: '',
                sessionId: '',
                environment: '',
                startDate: '',
                endDate: '',
                count: pagination.count,
                pageNo: 1
            });
            setCallLogGroup(response?.data.logs || [])
            setCallLogSummary({
                ...callLogSummary, totalCalls: response?.data.totalCalls,
                totalSuccessfulPayments: response?.data.totalSuccessfulPayments, totalFailedPayments: response?.data?.totalFailedPayments
            });
            // Clear the filter data and reset it to default
            const sessionResponse = await AuditService.getIvrSessionLogs({
                sessionId: detailedLogFilter.sessionId,
                count: paginationCallLogs.count,
                pageNo: 1
            });
            // set callLogs state with the updated values
            setCallLogs(sessionResponse?.data?.logs || []);
            setPaginationProps(response?.dataCount, 1);
            // reset filter option
            setFilterCallLogs({ text: '', stepName: '', dnid: '', callerIdNum: '', sessionId: '', environment: '', startDate: '', endDate: '' });
        } catch (err) {
            // opening the snackbar
            if (snackbarRef?.current) {
                snackbarRef.current.open(MESSAGES.SOMETHING_WENT_WRONG);
            }
        }
    }

    /**
     * Updates filter state for call logs
     * @param {string} field - field to update in filter state
     * @param {string} value - new value of the filter field
     */
    const handleInputChange = (field, value) => {
        setFilterCallLogs((prev) => {
            const newFilterCallLogs = { ...prev, [field]: value?.toLowerCase() };
            // Check if all fields are empty after input change, and reset
            const allFieldsEmpty = Object.values(newFilterCallLogs).every(val => val === '');
            if (allFieldsEmpty) {
                resetData();
            }
            return newFilterCallLogs;
        });
    }

    /**
     * Handles the scroll event to load more call logs if the user has scrolled to the bottom.
     * @param {Event} event - The scroll event.
     */
    const handleScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.target;
        const threshold = 5;
        // Check if the user has scrolled near the bottom.
        const bottom = scrollHeight - scrollTop - clientHeight <= threshold;
        // if user has scrolled to bottom and there are still some records left to fetch, then load call logs with updated pagination
        if (bottom && pagination.currentPage < pagination.totalPages) {
            // increment the current page with 1
            loadCallLogsGroup(pagination.currentPage + 1, 'next');
        }
    }
    /**
     * handleLogsListScroll, Handles the inner scroll event to detect when the user scrolls near the bottom of the container and triggers the loading of
     * session logs if necessary.
     * 
     * @param {Object} event - The scroll event object.
     */
    const handleLogsListScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.target;
        const threshold = 5;
        // Check if the user has scrolled near the bottom.
        const bottom = scrollHeight - scrollTop - clientHeight <= threshold;
        // if user has scrolled to bottom and there are still some records left to fetch, then load call logs with updated pagination
        if (bottom && pagination.currentPage < pagination.totalPages) {
            // increment the current page with 1
            loadCallLogs({ pageNo: paginationCallLogs.currentPage + 1, action: 'next' });
        }
    }

    /**
     * Update filters or load call logs (only if isSubmit is true) according to the selected date range
     * @param {{ startDate: string, endDate: string }} dateTimeRange
     * @param {boolean} isSubmit - if true, then it means load logs according to date time range, otherwise just update the filters
     */
    const handleDateTimeRangeChange = (dateTimeRange, isSubmit = false) => {
        const updatedFilters = {
            ...filterCallLogs,
            startDate: dateTimeRange.startDate,
            endDate: dateTimeRange.endDate
        };
        setFilterCallLogs(updatedFilters);
        // if enter key has been pressed i.e. submitted the date range
        if (isSubmit) {
            const pageNo = 1;
            loadCallLogsGroup(pageNo, 'reset', updatedFilters);
        }
    }

    return (
        <div className="logs-table-summary">
            <PmivrSnackbar ref={snackbarRef} />

            {/* dialog box to show more details of the log */}
            <PmivrDialog showDialog={logDetailsDialogProps.showLogInfoDialog} cssClass='modal-width-medium' title={"Log info"}
                closeDialog={() => setLogDetailsDialogProps({ showLogInfoDialog: false, currentLog: {} })}
                message={<LogInfo logInfo={logDetailsDialogProps.currentLog} />}
                footer={<></>} />

            {/* dialog box to show the advance search for call logs */}
            <PmivrDialog showDialog={uiState?.showFilterDialog} cssClass='modal-width-medium' title={"Advanced Search"}
                closeDialog={() => setUiState({ showFilterDialog: false })}
                message={
                    <div className="row p-2 pt-2">
                        <div className="col-md-6 pmivr-relative">
                            <PmivrLabel label="Step Name" tooltip={TOOLTIP.INFO.CALL_LOGS.STEP_NAME} cssClass="my-0 pb-1" />
                            <input type="text" id="stepNameBox" className="form-control pmivr-input" value={filterCallLogs.stepName}
                                placeholder="Step Name" onChange={(e) => handleInputChange('stepName', e.target.value)}
                                onKeyDown={(e) => {
                                    if (AppUtil.isEnterKey(e)) {
                                        loadCallLogsGroup(1, 'reset');
                                        loadCallLogs({ pageNo: 1, action: "reset" });
                                    }
                                }} />
                        </div>
                        <div className="col-md-6 pmivr-relative">
                            <PmivrLabel label="DNID" tooltip={TOOLTIP.INFO.CALL_LOGS.DNID} cssClass="my-0 pb-1" />
                            <input type="text" id="dnidBox" className="form-control pmivr-input" value={filterCallLogs.dnid}
                                placeholder="DNID" onChange={(e) => handleInputChange('dnid', e.target.value)}
                                onKeyDown={(e) => {
                                    if (AppUtil.isEnterKey(e)) {
                                        loadCallLogsGroup(1, 'reset');
                                        loadCallLogs({ pageNo: 1, action: "reset" });
                                    }
                                }} />
                        </div>
                        <div className="col-md-6 pmivr-relative mt-3">
                            <PmivrLabel label="Caller ID Number" tooltip={TOOLTIP.INFO.CALL_LOGS.CALLER_ID_NUMBER} cssClass="my-0 pb-1" />
                            <input type="text" id="callerIdNumBox" className="form-control pmivr-input" value={filterCallLogs.callerIdNum}
                                placeholder="Caller ID Number" onChange={(e) => handleInputChange('callerIdNum', e.target.value)}
                                onKeyDown={(e) => {
                                    if (AppUtil.isEnterKey(e)) {
                                        loadCallLogsGroup(1, 'reset');
                                        loadCallLogs({ pageNo: 1, action: "reset" });
                                    }
                                }} />
                        </div>
                        <div className={`col-md-6 pmivr-relative mt-3`}>
                            <PmivrLabel label="Session ID" tooltip={TOOLTIP.INFO.CALL_LOGS.SESSION_ID} cssClass="my-0 pb-1" />
                            <input type="text" id="sessionIdBox" className="form-control pmivr-input" value={filterCallLogs.sessionId}
                                placeholder="Session ID" onChange={(e) => handleInputChange('sessionId', e.target.value)}
                                onKeyDown={(e) => {
                                    if (AppUtil.isEnterKey(e)) {
                                        loadCallLogsGroup(1, 'reset');
                                        loadCallLogs({ pageNo: 1, action: "reset" });
                                    }
                                }} />
                        </div>
                        <div className={`col-md-6 pmivr-relative mt-3`}>
                            <PmivrLabel label="Business Code/TLA" tooltip={TOOLTIP.INFO.CALL_LOGS.BUSINESS_CODE} cssClass="my-0 pb-1" />
                            <input type="text" id="businessCodeBox" className="form-control pmivr-input" value={filterCallLogs.businessCode}
                                placeholder="Business Code/TLA" onChange={(e) => handleInputChange('businessCode', e.target.value)}
                                onKeyDown={(e) => {
                                    if (AppUtil.isEnterKey(e)) {
                                        loadCallLogsGroup(1, 'reset');
                                        loadCallLogs({ pageNo: 1, action: "reset" });
                                    }
                                }} />
                        </div>
                        {deploymentEnvironment?.environments?.length !== 0 ? (
                            <div className="col-md-6 pmivr-relative mt-2">
                                <PmivrLabel label="Environment" tooltip={TOOLTIP.INFO.CALL_LOGS.ENVIRONMENT} cssClass="my-0 pb-2" />
                                <select className="pmivr-select" value={filterCallLogs.environment}
                                    onChange={(e) => handleInputChange('environment', e.target.value)}>
                                    {
                                        deploymentEnvironment?.environments?.map((env) => {
                                            return (
                                                <option key={env.value} value={env.value} disabled={env.disabled}>
                                                    {env.text}
                                                </option>
                                            );
                                        })
                                    }
                                </select>
                            </div>
                        ) : <></>}
                        <div className="col-md-12 mt-3">
                            <PmivrDateTimeRangePicker onChange={handleDateTimeRangeChange}
                                initialValue={{ startDate: filterCallLogs.startDate || '', endDate: filterCallLogs.endDate || '' }} ref={dateTimeRef} />
                        </div>
                    </div>
                }
                footer={
                    <div className="d-flex align-items-end">
                        <button title="Reset call logs" type="button" onClick={() => resetData()}
                            className="float-start pmivr-btn-secondary pmivr-reset-link me-1">
                            Clear Search
                        </button>
                        <button title="Search call logs" type="button" onClick={() => {
                            loadCallLogsGroup(1, 'reset');
                            loadCallLogs({ pageNo: 1, action: "reset" })
                        }} className="float-start pmivr-btn-app pmivr-reset-link">
                            Search
                        </button>
                    </div>
                }
            />

            <div className="pmivr-filter-headers">
                <div className="row pt-1">
                    <div className="row border-bottom pb-3 pt-3">
                        <div className="col-lg-6">
                            <div className="px-3 pmivr-breadcrumb-list">
                                <Link to={APP_PAGES.HOME}>Home</Link> / Call Logs
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ backgroundColor: "#F5F6FA" }}>
                    {/* header for search and actions */}
                    <div className="row mx-2 py-4">
                        <div className="col-md-4">
                            <div className="row align-items-center rounded p-2 py-3 summary-items shadow-sm">
                                <div className="col-md-3  d-flex justify-content-center">
                                    <i className="bi bi-telephone fs-3 rounded-circle"
                                        style={{ color: "rgba(13, 110, 253, 1)", backgroundColor: "rgba(227,242,253,1)" }}></i>
                                </div>
                                <div className="col-md-9">
                                    <p className="mb-1 ">
                                        {callLogSummary?.totalCalls || 0}
                                    </p>
                                    <span className="text-muted mb-0 fs-6 ">Total Calls</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 ">
                            <div className="row align-items-center rounded mx-2 p-2 py-3 summary-items  shadow-sm">
                                <div className="col-md-3 d-flex justify-content-center">
                                    <i className="bi bi-check-circle fs-3 rounded-circle" style={{
                                        color: "rgba(0, 128, 0)", backgroundColor: "rgba(0, 128, 0, 0.155)"
                                    }}></i>
                                </div>
                                <div className="col-md-9">
                                    <p className="text-bold mb-1 "> {callLogSummary.totalSuccessfulPayments || 0} </p>
                                    <span className="text-muted mb-0 fs-6">Total Successful Payments</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 ">
                            <div className="row align-items-center rounded p-2 py-3 summary-items shadow-sm">
                                <div className="col-md-3 d-flex justify-content-center">
                                    <i className="bi bi-x-circle fs-3 rounded-circle"
                                        style={{ color: "rgba(204, 0, 0)", backgroundColor: "rgba(204, 0, 0, 0.331)" }}></i>
                                </div>
                                <div className="col-md-9">
                                    <p className="text-bold mb-1 "> {callLogSummary.totalFailedPayments || 0} </p>
                                    <span className="text-muted mb-0 fs-6">Total Failed Payments</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <CallLogsList
                        filterCallLogs={filterCallLogs} handleInputChange={handleInputChange} loadCallLogsGroup={loadCallLogsGroup} loadCallLogs={loadCallLogs} uiState={uiState} setUiState={setUiState} handleScroll={handleScroll} callLogGroup={callLogGroup} handleLogsListScroll={handleLogsListScroll} callLogs={callLogs} setLogDetailsDialogProps={setLogDetailsDialogProps} />
                </div>
            </div >
        </div>
    );
}

export default Logs;