import { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { APP_PAGES } from '../../../constants/app-pages';
import { envConfig } from '../../../environment';

import LEFT_BAR_OPTIONS from "./left-bar-options";
import PmivrTooltip from "../tooltip/pmivr-tooltip";
import UserAvatar from '../user-avatar/user-avatar';

import UserService from '../../../services/user.service';

/**
 * Left Bar Component in the app (diagram page, etc) for providing options to the user
 * @returns {React.Component} Html element to render left side bar 
 */
const LeftBarComponent = () => {
    // To set currently logged in user
    const [currentUser, setCurrentUser] = useState({});
    // getting the current url location object
    const location = useLocation();
    const { businessCode, versionId, flowType } = useSelector(state => state.client);


    useEffect(() => {
        LEFT_BAR_OPTIONS.map((option) => {
            // option to show link to the page , in starting it is true
            // showLink is a flag to show link to specific page on a particular page
            // example : on wizard page do not show list voice file and variables page link
            // as it is associated with diagram page and not wizard page
            option.showLink = true;
            // option.linkTo should only be updated only when it is on diagram page
            if (location.pathname?.startsWith(APP_PAGES.DIAGRAM)) {
                // updating the link and id of the diagram option,
                // as it requires client info (versionId, flow status, etc) as per selection
                if (option.id?.startsWith(APP_PAGES.DIAGRAM)) {
                    option.linkTo = location.pathname;
                    option.id = location.pathname;
                }
            }
            else if (location.pathname?.startsWith(APP_PAGES.LIST_VOICE_FILE) || location.pathname?.startsWith(APP_PAGES.VARIABLES)) {
                if (option.id?.startsWith(APP_PAGES.WIZARD)) {
                    // on list voice file and variables page do not show wizard link
                    option.showLink = false;
                }
            }
            // if user is on wizard page and from their if go to diagram page then update the url query params
            // as businessCode , flowType and version id is reqd
            else if (location.pathname?.startsWith(APP_PAGES.WIZARD)) {
                if (option.id?.startsWith(APP_PAGES.DIAGRAM)) {
                    option.linkTo = `${APP_PAGES.DIAGRAM}/${businessCode}/${flowType}/${versionId}`;
                } else if (option.id?.startsWith(APP_PAGES.LIST_VOICE_FILE) || option.id?.startsWith(APP_PAGES.VARIABLES)) {
                    // on wizard page do not show list voice file and variables page link ,
                    // since it is associated with diagram page and not wizard
                    option.showLink = false;
                }
            }
            // update wizard link to open client flows page to edit
            if (option.id?.startsWith(APP_PAGES.WIZARD)) {
                option.linkTo = `${APP_PAGES.WIZARD}/${businessCode}?clientFlowExists=true`;
            }
            // returning the option variable because map method needs a return statement
            return option;
        });

        // Setting the currently logged in user.
        setCurrentUser(UserService.getCurrentUser());
    }, []);

    return (
        <div className="pmivr-leftbar">
            <div className="logo pt-2  text-center"><img src={envConfig.PUBLIC_URL + '/images/paymentus-icon-short.png'} alt="" /></div>
            <ul className="nav navbar-nav text-center leftbar-nav">
                {
                    LEFT_BAR_OPTIONS.map((item) => {
                        // making the currently selected option as active by adding class active
                        return (item?.showLink && <li key={item.title} className={(location.pathname?.startsWith(item.id)) ? 'active' : ''}>
                            {
                                item.linkTo ?
                                    <Link to={item.linkTo} target={(location.pathname?.startsWith(item.id)) ? "_self" : "_blank"}>
                                        <PmivrTooltip message={item.title}>
                                            <i className={item.iconClass}></i>
                                        </PmivrTooltip>
                                    </Link>
                                    :
                                    <PmivrTooltip message={item.title}>
                                        <i className={item.iconClass}></i>
                                    </PmivrTooltip>

                            }
                        </li>
                        )
                    })
                }
            </ul>

            <ul>
                <div className="pmivr-footer sidebar-user-avatar">
                    <UserAvatar user={currentUser} />
                </div>
            </ul>
        </div>
    )
}

export { LeftBarComponent }